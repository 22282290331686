import { ChangeEvent, useState } from 'react';

import * as S from './styles';
import { ModalInputProps } from './types';

import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

export const ModalInput = ({
  id,
  value,
  onChange,
  mask,
  type,
  label,
  placeholder,
  isRequired,
  disabled,
}: ModalInputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <S.ModalInputContainer>
      <S.ModalInputLabel isRequired={isRequired} htmlFor={id}>
        {label}
      </S.ModalInputLabel>
      <S.ModalInputWrapper>
        <S.ModalInput
          id={id}
          type={type === 'password' && !showPassword ? 'password' : 'text'}
          mask={mask}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChange(event.currentTarget.value)
          }
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          autoComplete="off"
        />
        {type === 'password' &&
          (showPassword ? (
            <AiFillEye
              size="22px"
              color="#B8BCCA"
              onClick={() => setShowPassword(false)}
              data-testid="hide-password-button"
            />
          ) : (
            <AiFillEyeInvisible
              size="22px"
              color="#B8BCCA"
              onClick={() => setShowPassword(true)}
              data-testid="show-password-button"
            />
          ))}
      </S.ModalInputWrapper>
    </S.ModalInputContainer>
  );
};

ModalInput.defaultProps = {
  type: 'text',
  isRequired: false,
  mask: '',
  disabled: false,
};
