import * as S from './styles';
import { FooterProps } from './types';

export const Footer = ({ children }: FooterProps) => {
  return (
    <>
      <S.Container>
        <S.Content>{children}</S.Content>
      </S.Container>
    </>
  );
};
