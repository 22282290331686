import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 380px;
  background: var(--light-green-1);
  border-radius: 10px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #495b4b;
  position: relative;
  overflow: hidden;
`;

export const textWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 30px;
  flex: 1;
  font-family: IBM Plex Sans;
  font-weight: 500;
  letter-spacing: 0px;
  overflow: overlay;
  text-align: left;

  h3 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 10px;
  }

  span {
    font-size: 16px;
    line-height: 22px;
  }

  @media (max-width: 768px) {
    padding: 12px;

    span {
      font-weight: 400;
    }
  }
`;

export const ResponseButton = styled.button`
  align-self: flex-end;
  display: flex;
  margin-top: 8px;
  align-items: center;
  padding: 4px 10px;
  border-radius: 4px 0 0 4px;
  background: rgba(140, 170, 142, 0.1);
  border: none;
  color: #495b4b;
  font-size: 16px;
  font-weight: 500;

  & > svg {
    margin-left: 10px;
  }
`;
