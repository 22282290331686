import { GlobalStyle } from './styles/global';
import BionicRoutes from './routes';
import { BionicProvider } from './BionicContext';

export function App () {
  return (
    <BionicProvider>
      <BionicRoutes />
      <GlobalStyle />
    </BionicProvider>
  );
}

export default App;
