import styled from 'styled-components';

export const TwoButtonDefaultWrapper = styled.div`
  display: flex;
  border: 1px solid var(--green);
  border-radius: 9px 9px 0px 0px;
  overflow: hidden;
  height: 56px;
  width: 254px;

  button {
    flex: 1;
    border-radius: unset;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
