import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { BionicContext } from '../BionicContext';
import Rating from '../components/pages/Rating';
import Comment from '../components/pages/Comment';
import { Home } from '../components/pages/Home';
import { About } from '../components/pages/About';
import { useContext, useEffect, useState } from 'react';

const BionicRoutes: React.FunctionComponent = () => {
  const [renderRoutes, setRenderRoutes] = useState(false);
  const { getTokenValidation } = useContext(BionicContext);

  useEffect(() => {
    getTokenValidation().finally(() => {
      setRenderRoutes(true);
    });
  }, [getTokenValidation, renderRoutes]);

  if (!renderRoutes) {
    return <div>Carregando...</div>;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<About />} path="/about" />
        <Route element={<Rating />} path="/company/:company_id/ratings" />
        <Route
          element={<Comment />}
          path="/company/:company_id/comment/:comment_id"
        />
      </Routes>
    </BrowserRouter>
  );
};

export default BionicRoutes;
