import styled from 'styled-components';

export const MainWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 30px 62px;
  max-width: 1440px;
  width: 100%;
  margin: auto;

  @media (max-width: 768px) {
    padding: 30px 20px;

    h1 {
      font-size: 22px;
    }
  }
`;

export const MainContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin-top: 48px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 36px;
    gap: 24px;
  }
`;
