import Modal from 'react-modal';
import { ModalProps } from './types';
import * as S from './styles';
import { AiOutlineLeft } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';

Modal.setAppElement('#root');

export const ModalComponent = ({
  isModalOpen,
  children,
  handleCloseModal,
  className,
}: ModalProps) => {
  return (
    <Modal
      className={`react-modal-content ${className}`}
      isOpen={isModalOpen}
      onRequestClose={handleCloseModal}
      overlayClassName="react-modal-overlay"
    >
      <S.BackButton onClick={handleCloseModal}>
        <AiOutlineLeft size="20px" />
        Voltar
      </S.BackButton>
      <S.CloseButton onClick={handleCloseModal}>
        <IoMdClose size="24px" color="#000" />
      </S.CloseButton>
      {children}
    </Modal>
  );
};
