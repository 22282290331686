import { useContext, useEffect, useState } from 'react';
import { BionicContext } from '../../../BionicContext';
import { Button } from '../../atoms/Button';
import { ModalInput } from '../../atoms/ModalInput';
import * as S from './styles';
import { ForgotPasswordScreenProps } from './types';

export const ForgotPasswordScreen = ({
  isModalOpen,
  handleCloseModal,
  identifier,
}: ForgotPasswordScreenProps) => {
  const { postNewPassword, postResetPassword } = useContext(BionicContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [repeatedPasswordValue, setRepeatedPasswordValue] = useState('');
  const [newPasswordValue, setNewPasswordValue] = useState('');
  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(() => {
    setNewPasswordValue('');
    setRepeatedPasswordValue('');
    setShowFeedback(false);
  }, [isModalOpen]);

  useEffect(() => {
    setShowFeedback(false);
  }, [newPasswordValue, repeatedPasswordValue]);

  const isValidPassword = () => newPasswordValue === repeatedPasswordValue;

  const handleSubmit = async () => {
    if (!isValidPassword()) {
      setShowFeedback(true);
      return;
    }

    try {
      setIsLoading(true);
      await postNewPassword(verificationCode, newPasswordValue);
      handleCloseModal();
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  const resendCodeSubmit = async () => {
    if (isSendingCode) return;
    try {
      setIsSendingCode(true);
      await postResetPassword(identifier.replace(/\D/g, ''));
    } catch (err) {
      console.warn(err);
    } finally {
      setIsSendingCode(false);
    }
  };

  return (
    <S.ModalContentWrapper>
      <S.TitleWrapper>
        <h3>É rapidinho!</h3>
        <span>
          Para sua segurança enviamos um código de confirmação ao seu e-mail
        </span>
      </S.TitleWrapper>
      <ModalInput
        id="verification-code"
        value={verificationCode}
        isRequired
        onChange={setVerificationCode}
        placeholder="**********"
        label="Informe o código que te enviamos por e-mail"
      />
      <S.ResendPasswordSpan onClick={resendCodeSubmit}>
        REENVIAR CÓDIGO
      </S.ResendPasswordSpan>
      <ModalInput
        type="password"
        id="senha-nova"
        value={newPasswordValue}
        onChange={setNewPasswordValue}
        placeholder="Informe a nova senha"
        label="Digite a nova senha"
      />
      <ModalInput
        type="password"
        id="senha-nova-repetida"
        value={repeatedPasswordValue}
        onChange={setRepeatedPasswordValue}
        placeholder="Informe a nova senha"
        label="Digite Novamente a nova senha"
      />
      <S.FeedbackSpan show={showFeedback}>
        As senhas digitadas não conferem
      </S.FeedbackSpan>
      <S.ButtonWrapper>
        <Button
          background="green"
          color="white"
          className="confirm-button"
          onClick={handleSubmit}
          disabled={
            !verificationCode || !newPasswordValue || !repeatedPasswordValue
          }
          loading={isLoading}
        >
          Atualizar senha
        </Button>
      </S.ButtonWrapper>
    </S.ModalContentWrapper>
  );
};
