import styled from 'styled-components';

export const RatingComment = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export const CommentDescriptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: var(--text-secondary-gray-2);

  & > span:first-child {
    font-weight: 500;
  }

  & > div {
    display: flex;
    align-items: center;
  }
`;

export const CommentWrapper = styled.div`
  display: flex;
  background: var(--light-green-2);
  border-radius: 10px;
  box-shadow: 0 4px 2px -1px var(--text-secondary-gray-3);
  flex-direction: column;
  padding: 12px;
  color: #3b4256;
  position: relative;
  overflow: hidden;
`;

export const ResponseButton = styled.button`
  align-self: flex-end;
  display: flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 4px 0 0 4px;
  margin-right: -12px;
  margin-bottom: -12px;
  background: rgba(140, 170, 142, 0.1);
  border: none;
  color: #495b4b;
  font-size: 12px;
  font-weight: 500;

  & > svg {
    margin-left: 10px;
  }
`;
