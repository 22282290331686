import { memo } from 'react';

import * as S from './styles';

import { Header } from '../../templates/Header';

import { AboutUsDescription } from '../../molecules/AboutUsDescription';
import { Footer } from '../../molecules/Footer';
import { AboutUs } from '../../molecules/AboutUs';

export const About = () => {
  return (
    <>
      <Header currentPage="about" />
      <S.MainWrapper>
        <h1>Quem somos</h1>
        <S.MainContentWrapper>
          <AboutUs />
          <AboutUsDescription />
        </S.MainContentWrapper>
      </S.MainWrapper>

      <Footer />
    </>
  );
};

export default memo(About);
