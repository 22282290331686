import Spin from '../Spin';
import * as S from './styles';
import { ButtonProps } from './types';

export const Button = ({
  onClick,
  background,
  children,
  color,
  loading,
  disabled,
  className,
}: ButtonProps) => {
  return (
    <S.LoadingButtonRoot
      background={background}
      color={color}
      onClick={() => onClick()}
      disabled={loading || disabled}
      className={`${loading ? 'loading' : ''} ${className}`}
    >
      {loading && (
        <div className="loading-spin">
          <Spin size={20} />
        </div>
      )}
      {children}
    </S.LoadingButtonRoot>
  );
};

Button.defaultProps = {
  background: 'white',
  color: 'black',
};
