import styled from 'styled-components';

export const RatingHeartsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  p {
    font-size: 14px;
    font-weight: 500;
  }
`;
