import styled from 'styled-components';
import diversity from '../../../assets/diversity.svg';

export const Container = styled.footer``;

export const Content = styled.div`
  max-width: 1440px;
  width: 100%;
  background-image: url(${diversity});
  background-position: center top;
  background-size: cover;
  padding-top: 22%;
  height: 0;
  margin: auto;
`;
