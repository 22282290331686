import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BionicContext } from '../../../BionicContext';

import { SupportModal } from '../../organisms/SupportModal';
import { ModalComponent } from '../../molecules/Modal';
import * as S from './styles';
import { MenuModalProps } from './types';
import { BiHelpCircle, BiHomeHeart, BiLogIn } from 'react-icons/bi';
import { FcAbout } from 'react-icons/fc';
import { RxAvatar } from 'react-icons/rx';

export const MenuModal = ({
  isModalOpen,
  handleCloseModal,
  firstButtonOnClick,
  secondButtonOnClick,
}: MenuModalProps) => {
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
  const { isLogged, isCompany, userId, userName } = useContext(BionicContext);

  const navigate = useNavigate();
  const handleAboutUsPush = () => {
    navigate('/about');
  };

  const handleHomePush = () => {
    navigate('/');
  };

  const handleRatingPush = () => {
    navigate(`/company/${userId}/ratings`);
  };

  const redirectUser = () => {
    if (isLogged && isCompany) {
      handleRatingPush();
    } else {
      handleHomePush();
    }
  };

  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
    >
      <S.ModalContainer>
        {userName && (
          <h3>Olá, {userName.charAt(0).toUpperCase() + userName.slice(1)}!</h3>
        )}
        <S.MenuOptionsWrapper>
          {!isLogged && (
            <>
              <S.Options
                onClick={() => {
                  redirectUser();
                  handleCloseModal();
                }}
              >
                <BiHomeHeart size="36px" />
                <h3>Página inicial</h3>
              </S.Options>
              <S.Options
                onClick={() => {
                  firstButtonOnClick();
                  handleCloseModal();
                }}
              >
                <BiLogIn size="36px" />
                <h3>Entrar</h3>
              </S.Options>
              <S.Options
                onClick={() => {
                  secondButtonOnClick();
                  handleCloseModal();
                }}
              >
                <RxAvatar size="36px" />
                <h3>Registrar</h3>
              </S.Options>
            </>
          )}
          <S.Options
            onClick={() => {
              handleAboutUsPush();
              handleCloseModal();
            }}
          >
            <FcAbout size="36px" />
            <h3>Quem somos</h3>
          </S.Options>
          <S.Options onClick={() => setIsSupportModalOpen(true)}>
            <BiHelpCircle size="36px" />
            <h3>Suporte</h3>
          </S.Options>
        </S.MenuOptionsWrapper>
      </S.ModalContainer>
      <SupportModal
        isModalOpen={isSupportModalOpen}
        handleCloseModal={() => setIsSupportModalOpen(false)}
      />
    </ModalComponent>
  );
};
