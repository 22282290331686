import { useContext, useEffect, useState } from 'react';
import { BionicContext } from '../../../BionicContext';
import { isMobileDevice } from '../../../utils';

import { Button } from '../../atoms/Button';
import { ModalInput } from '../../atoms/ModalInput';
import { FormButtonSwitcher } from '../../molecules/FormButtonSwitcher';
import { InsertVerificationCodeScreen } from '../../molecules/InsertVerificationCodeScreen';
import { ModalComponent } from '../../molecules/Modal';
import { ProceedLoginScreen } from '../../molecules/ProceedLoginScreen';
import { companyInfo, userInfo } from './constants';
import * as S from './styles';
import { SignUpMode, SignUpModalProps } from './types';

export const SignUpModal = ({
  isModalOpen,
  handleCloseModal,
}: SignUpModalProps) => {
  const { postSignUp } = useContext(BionicContext);
  const [isLoading, setIsLoading] = useState(false);
  const [fNameValue, setFNameValue] = useState('');
  const [sNameValue, setSNameValue] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [mailValue, setMailValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [signUpMode, setSignUpMode] = useState<SignUpMode>('user');
  const [modalStep, setModalStep] = useState(1);

  useEffect(() => {
    setFNameValue('');
    setSNameValue('');
    setIdentifier('');
    setMailValue('');
    setPasswordValue('');
    // setModalStep(1);
  }, [signUpMode, isModalOpen]);

  const handleSubmit = async () => {
    const formattedIdentifier = identifier.replace(/\D/g, '');
    try {
      setIsLoading(true);
      await postSignUp({
        first_name: fNameValue,
        second_name: sNameValue,
        email: mailValue,
        identifier: formattedIdentifier,
        password: passwordValue,
        is_company: !isUser(),
      });

      if (!isUser()) {
        setModalStep(2);
      } else {
        handleCloseModal();
      }
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  const isUser = () => {
    return signUpMode === 'user';
  };

  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
    >
      {modalStep === 1 && (
        <S.ModalContentWrapper>
          <S.TitleWrapper>
            {isMobileDevice() ? (
              <h3>Vamos lá!</h3>
            ) : (
              <>
                <h3>Como você gostaria de se registrar?</h3>
                <FormButtonSwitcher
                  selectedOption={isUser() ? 'first' : 'second'}
                  firstButtonLabel="Cliente"
                  secondButtonLabel="Empresa"
                  firstButtonOnClick={() => setSignUpMode('user')}
                  secondButtonOnClick={() => setSignUpMode('company')}
                />
              </>
            )}
          </S.TitleWrapper>
          <ModalInput
            id="fname"
            value={fNameValue}
            onChange={setFNameValue}
            isRequired={true}
            placeholder={isUser() ? userInfo.fname : companyInfo.fname}
            label={isUser() ? 'Nome' : 'Nome fantasia'}
          />
          <ModalInput
            id="sname"
            value={sNameValue}
            onChange={setSNameValue}
            isRequired={true}
            placeholder={isUser() ? userInfo.sname : companyInfo.sname}
            label={isUser() ? 'Sobrenome' : 'Razão social'}
          />
          <ModalInput
            id="identifier"
            value={identifier}
            onChange={setIdentifier}
            isRequired={true}
            mask={isUser() ? '999.999.999-99' : '99.999.999/9999-99'}
            placeholder={isUser() ? '000.000.000-00' : '00.000.000/0000-00'}
            label={isUser() ? 'CPF' : 'CNPJ'}
          />
          <ModalInput
            id="mail"
            value={mailValue}
            onChange={setMailValue}
            isRequired={true}
            placeholder="Nome@email.com"
            label="E-mail"
          />
          <ModalInput
            type="password"
            id="senha"
            value={passwordValue}
            onChange={setPasswordValue}
            isRequired={true}
            placeholder="Mínimo 8 caracteres"
            label="Senha"
          />
          <Button
            background="green"
            color="white"
            className="sign-button"
            onClick={() => handleSubmit()}
            loading={isLoading}
            disabled={
              !passwordValue ||
              !mailValue ||
              !identifier ||
              !fNameValue ||
              !sNameValue
            }
          >
            Cadastrar-se
            {!isMobileDevice() &&
              (isUser() ? ' como cliente' : ' como empresa')}
          </Button>
        </S.ModalContentWrapper>
      )}

      {modalStep === 2 && (
        <InsertVerificationCodeScreen setNextStep={() => setModalStep(3)} />
      )}
      {modalStep === 3 && <ProceedLoginScreen />}
    </ModalComponent>
  );
};
