import { createContext, ReactNode, useCallback, useState } from 'react';
import { SnackBarAlertProps } from './components/atoms/SnackBar/types';
import { CompanyInfoProps } from './components/molecules/CompanyInfo/types';
import { CompanyRatingCardProps } from './components/molecules/CompanyRatingCard/types';
import { RatingCardProps } from './components/molecules/RatingCard/types';
import { CommentProps } from './components/pages/Comment/types';
import userServices from './services/services';
import {
  ContactSupportServiceProps,
  SignInServiceProps,
  RateCompanyServiceProps,
  AnswerCommentServiceProps,
  UpdateInfoServiceProps,
  RegisterCompanyServiceProps,
  SignUpServiceProps,
  GetUserInfoProps,
} from './services/services.types';
import { getAuthCookie, isMobileDevice } from './utils';

interface BionicProviderProps {
  children: ReactNode;
}

interface BionicContextData {
  userId: string;
  userName: string;
  isLoading: boolean;
  isLogged: boolean;
  isCompany: boolean;
  getRatingList: () => Promise<RatingCardProps[]>;
  getUserInfo: () => Promise<GetUserInfoProps>;
  searchCompanies: CompanyRatingCardProps[];
  setSearchCompanies: React.Dispatch<
    React.SetStateAction<CompanyRatingCardProps[]>
  >;
  logout: () => void;
  postSignIn: (signInData: SignInServiceProps) => Promise<void>;
  postSignUp: (signUpData: SignUpServiceProps) => Promise<void>;
  postContactSupport: (
    contactSupportData: ContactSupportServiceProps
  ) => Promise<void>;
  postRateCompany: (rateCompanyData: RateCompanyServiceProps) => Promise<void>;
  postAnswerComment: (
    answerCommentData: AnswerCommentServiceProps
  ) => Promise<void>;
  getSearchCompanies: (searchBy: string) => Promise<void>;
  postResetPassword: (identifier: string) => Promise<void>;
  postResendVerificationCode: (identifier: string) => Promise<void>;
  postCheckVerificationCode: (verificationCode: string) => Promise<void>;
  getCommentDetails: (comment_id: string) => Promise<CommentProps>;
  postNewPassword: (
    verificationCode: string,
    newPassword: string
  ) => Promise<void>;
  putUpdateInfo: (UpdateInfoData: UpdateInfoServiceProps) => Promise<void>;
  registerCompany: (
    registerCompanyData: RegisterCompanyServiceProps
  ) => Promise<void>;
  getCompanyDetails: (company_id: string) => Promise<void>;
  snackBarAlertProps: SnackBarAlertProps;
  handleCloseSnackBar: () => void;
  companyInfo: CompanyInfoProps;
  getTokenValidation: () => Promise<void>;
}

const initialAlertState: SnackBarAlertProps = {
  show: false,
  type: 'success',
  message: '',
};

export const BionicContext = createContext<BionicContextData>(
  {} as BionicContextData
);

export const BionicProvider = ({ children }: BionicProviderProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [companyInfo, setCompanyInfo] = useState<CompanyInfoProps>(
    {} as CompanyInfoProps
  );
  const [searchCompanies, setSearchCompanies] = useState<
    CompanyRatingCardProps[]
  >([]);
  const [isLogged, setIsLogged] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState('');
  const [snackBarAlertProps, setSnackBarAlertProps] =
    useState(initialAlertState);

  const handleCloseSnackBar = () => setSnackBarAlertProps(initialAlertState);

  const setErrorSnackBar = (message: string) =>
    setSnackBarAlertProps({
      type: 'error',
      message,
      show: true,
    });

  const getRatingList = useCallback(async () => {
    try {
      setIsLoading(true);
      let data = isLogged
        ? await userServices.getRatingListWithUserLogged(userId)
        : await userServices.getRatingList();
      return data;
    } catch (error: any) {
      setErrorSnackBar(error.response.data.message);
      throw new Error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  }, [isLogged, userId]);

  const getUserInfo = useCallback(async () => {
    try {
      return await userServices.getUserInfo(userId);
    } catch (error: any) {
      setErrorSnackBar(error.response.data.message);
      throw new Error(error.response.data.message);
    }
  }, [userId]);

  const getCompanyDetails = useCallback(
    async (company_id: string) => {
      try {
        setIsLoading(true);
        const data = isLogged
          ? await userServices.getCompanyDetailsWithUserLogged(
              userId,
              company_id
            )
          : await userServices.getCompanyDetails(company_id);
        setCompanyInfo(data);
      } catch (error: any) {
        setErrorSnackBar(error.response.data.message);
        throw new Error(error.response.data.message);
      } finally {
        setIsLoading(false);
      }
    },
    [isLogged, userId]
  );

  const getCommentDetails = useCallback(
    async (comment_id: string) => {
      try {
        setIsLoading(true);
        const data = await userServices.getCommentDetails(userId, comment_id);
        return data;
      } catch (error: any) {
        setErrorSnackBar(error.response.data.message);
        throw new Error(error.response.data.message);
      } finally {
        setIsLoading(false);
      }
    },
    [userId]
  );

  const getSearchCompanies = async (searchBy: string) => {
    if (searchBy) {
      try {
        setIsLoading(true);
        const data = await userServices.getSearchCompanies(searchBy);
        setSearchCompanies(data);
      } catch (error: any) {
        setErrorSnackBar(error.response.data.message);
        throw new Error(error.response.data.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const logout = () => {
    setIsLogged(false);
    setUserId('');
    setIsCompany(false);
    document.cookie = `authorization=;expires=${new Date().setDate(
      new Date().getDate() - 1
    )};path=/`;
    setSnackBarAlertProps({
      ...initialAlertState,
      message:
        'Logout efetuado com sucesso! Faça login novamente caso queira avaliar uma empresa',
      show: true,
    });
  };

  const postSignIn = async (signInData: SignInServiceProps) => {
    try {
      const data = await userServices.postSignIn(signInData);
      if (isMobileDevice() && data.is_company) {
        setErrorSnackBar(
          'Infelizmente ainda não temos suporte para empresas no celular, tente pelo computador'
        );
      } else {
        setIsLogged(true);
        setUserId(data.identifier);
        setIsCompany(data.is_company);
        setUserName(data.name);
        document.cookie = `authorization=${
          data.token_string
        };expires=${new Date().setDate(new Date().getDate() + 1)};path=/`;
        setSnackBarAlertProps({
          ...initialAlertState,
          message: 'Login efetuado com sucesso! Boas vindas!',
          show: true,
        });
      }
    } catch (error: any) {
      setErrorSnackBar(error.response.data.message);
      throw new Error(error.response.data.message);
    }
  };

  const postSignUp = async (signUpData: SignUpServiceProps) => {
    try {
      await userServices.postSignUp(signUpData);
      if (signUpData.is_company) {
        sessionStorage.setItem('identifier', signUpData.identifier);
        return;
      } else {
        setSnackBarAlertProps({
          ...initialAlertState,
          message:
            'Registro efetuado com sucesso! Faça seu login e boas vindas!',
          show: true,
        });
      }
    } catch (error: any) {
      setErrorSnackBar(error.response.data.message);
      throw new Error(error.response.data.message);
    }
  };

  const postResetPassword = async (identifier: string) => {
    try {
      await userServices.postResetPassword(identifier);
      setSnackBarAlertProps({
        ...initialAlertState,
        message:
          'Um código para redefinição de senha foi enviado para o seu e-mail',
        show: true,
      });
    } catch (error: any) {
      setErrorSnackBar(error.response.data.message);
      throw new Error(error.response.data.message);
    }
  };

  const postResendVerificationCode = async (identifier: string) => {
    try {
      await userServices.postResendVerificationCode(identifier);
      setSnackBarAlertProps({
        ...initialAlertState,
        message:
          'Um código de verificação foi enviado para o seu e-mail, verifique sua caixa de spam caso não encontre na caixa de entrada',
        show: true,
      });
    } catch (error: any) {
      setErrorSnackBar(error.response.data.message);
      throw new Error(error.response.data.message);
    }
  };

  const postCheckVerificationCode = async (verificationCode: string) => {
    try {
      await userServices.postCheckVerificationCode(verificationCode);
    } catch (error: any) {
      setErrorSnackBar(error.response.data.message);
      throw new Error(error.response.data.message);
    }
  };

  const getTokenValidation = useCallback(async () => {
    const currentCookie = getAuthCookie();
    if (currentCookie) {
      try {
        setIsLoading(true);
        const data = await userServices.getTokenValidation(currentCookie);
        setIsLogged(!data.is_token_expired);
        setUserId(data.identifier);
        setUserName(data.name);
        setIsCompany(data.is_company);
      } catch (e) {
        setIsLogged(false);
        setErrorSnackBar('Sessão expirada, faça login novamente');
        document.cookie = `authorization=;expires=${new Date().setDate(
          new Date().getDate() - 1
        )};path=/`;
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  const postContactSupport = async (
    contactSupportData: ContactSupportServiceProps
  ) => {
    try {
      await userServices.postContactSupport(contactSupportData);
      setSnackBarAlertProps({
        ...initialAlertState,
        message:
          'Ticket registrado com sucesso! Entraremos em contato em breve',
        show: true,
      });
    } catch (error: any) {
      setErrorSnackBar(error.response.data.message);
      throw new Error(error.response.data.message);
    }
  };

  const postRateCompany = async (rateCompanyData: RateCompanyServiceProps) => {
    try {
      const tempCompanyId = rateCompanyData.companyId;
      await userServices.postRateCompany(rateCompanyData, userId);
      setSnackBarAlertProps({
        ...initialAlertState,
        message: 'Avaliação registrada com sucesso!',
        show: true,
      });
      getCompanyDetails(tempCompanyId!);
    } catch (error: any) {
      setErrorSnackBar(error.response.data.message);
      throw new Error(error.response.data.message);
    }
  };

  const postNewPassword = async (
    verificationCode: string,
    newPassword: string
  ) => {
    try {
      await userServices.postNewPassword(verificationCode, newPassword);
      setSnackBarAlertProps({
        ...initialAlertState,
        message: 'Senha alterada com sucesso!',
        show: true,
      });
    } catch (error: any) {
      setErrorSnackBar(error.response.data.message);
      throw new Error(error.response.data.message);
    }
  };

  const postAnswerComment = async (
    answerCommentData: AnswerCommentServiceProps
  ) => {
    try {
      await userServices.postAnswerComment(answerCommentData, userId);
      setSnackBarAlertProps({
        ...initialAlertState,
        message: 'Resposta registrada com sucesso!',
        show: true,
      });
    } catch (error: any) {
      setErrorSnackBar(error.response.data.message);
      throw new Error(error.response.data.message);
    }
  };

  const putUpdateInfo = async (updateInfoData: UpdateInfoServiceProps) => {
    try {
      await userServices.putUpdateInfo(updateInfoData);
      setSnackBarAlertProps({
        ...initialAlertState,
        message: 'Senha alterada com sucesso!',
        show: true,
      });
    } catch (error: any) {
      setErrorSnackBar(error.response.data.message);
      throw new Error(error.response.data.message);
    }
  };

  const registerCompany = async (
    registerCompanyData: RegisterCompanyServiceProps
  ) => {
    try {
      await userServices.registerCompany(registerCompanyData);
    } catch (e) {
      throw new Error('Erro ao registrar empresa');
    }
  };

  return (
    <BionicContext.Provider
      value={{
        userId,
        userName,
        getRatingList,
        companyInfo,
        searchCompanies,
        getUserInfo,
        setSearchCompanies,
        isLoading,
        isLogged,
        isCompany,
        logout,
        postSignIn,
        postSignUp,
        postResetPassword,
        postResendVerificationCode,
        postCheckVerificationCode,
        postNewPassword,
        postContactSupport,
        postRateCompany,
        getSearchCompanies,
        postAnswerComment,
        putUpdateInfo,
        registerCompany,
        snackBarAlertProps,
        handleCloseSnackBar,
        getCompanyDetails,
        getCommentDetails,
        getTokenValidation,
      }}
    >
      {children}
    </BionicContext.Provider>
  );
};
