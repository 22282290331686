import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Header } from '../../templates/Header';
import { BionicContext } from '../../../BionicContext';

import * as S from './styles';

import { CompanyInfo } from '../../molecules/CompanyInfo';
import { ListRatingComment } from '../../organisms/ListRatingComment';
import Spin from '../../atoms/Spin';
import SnackBar from '../../atoms/SnackBar';

export const Rating = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    getCompanyDetails,
    companyInfo,
    handleCloseSnackBar,
    snackBarAlertProps,
  } = useContext(BionicContext);
  const { company_id } = useParams();

  const callGetCompanyDetails = useCallback(async () => {
    try {
      setIsLoading(true);
      company_id && (await getCompanyDetails(company_id));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [company_id, getCompanyDetails]);

  useEffect(() => {
    callGetCompanyDetails();
  }, [callGetCompanyDetails, company_id, getCompanyDetails]);

  return (
    <>
      <Header />
      <S.ContentWrapper>
        {companyInfo.comments && !isLoading ? (
          <>
            <CompanyInfo {...companyInfo} company_id={company_id || ''} />
            <ListRatingComment
              comments={companyInfo.comments}
              is_company_admin={companyInfo.is_company_admin}
            />
          </>
        ) : (
          <S.SpinWrapper>
            <Spin size={60} />
          </S.SpinWrapper>
        )}
        {snackBarAlertProps.show && (
          <SnackBar
            type={snackBarAlertProps.type}
            onClose={handleCloseSnackBar}
            open={snackBarAlertProps.show}
          >
            {snackBarAlertProps.message}
          </SnackBar>
        )}
      </S.ContentWrapper>
    </>
  );
};

export default Rating;
