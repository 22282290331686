import { useContext, useState } from 'react';
import { BionicContext } from '../../../BionicContext';
import { Button } from '../../atoms/Button';
import { ModalInput } from '../../atoms/ModalInput';
import * as S from './styles';
import { InsertVerificationCodeScreenProps } from './types';
import security from '../../../assets/security.svg';

export const InsertVerificationCodeScreen = ({
  setNextStep,
}: InsertVerificationCodeScreenProps) => {
  const { postResendVerificationCode, postCheckVerificationCode } =
    useContext(BionicContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await postCheckVerificationCode(verificationCode);
      setNextStep();
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  const resendCodeSubmit = async () => {
    if (isSendingCode) return;
    try {
      setIsSendingCode(true);
      const identifier = sessionStorage.getItem('identifier');
      if (identifier) {
        await postResendVerificationCode(identifier);
      } else {
        alert(
          'Não foi possível reenviar o código de verificação. Tente novamente mais tarde.'
        );
      }
    } catch (err) {
      console.warn(err);
    } finally {
      setIsSendingCode(false);
    }
  };

  return (
    <S.ModalContentWrapper>
      <S.TitleWrapper>
        <h3>Estamos quase finalizando</h3>
        <span>
          Para sua segurança enviamos um código de confirmação ao seu e-mail
        </span>
      </S.TitleWrapper>
      <img alt="seguranca" src={security} />
      <ModalInput
        id="verificationCode"
        value={verificationCode}
        onChange={setVerificationCode}
        isRequired={true}
        placeholder="*********"
        label="Informe o código que te enviamos por e-mail"
      />
      <S.ResendPasswordSpan onClick={resendCodeSubmit}>
        REENVIAR CÓDIGO
      </S.ResendPasswordSpan>
      <S.ButtonWrapper>
        <Button
          background="green"
          color="white"
          className="confirm-button"
          onClick={handleSubmit}
          disabled={!verificationCode}
          loading={isLoading}
        >
          Finalizar Cadastro
        </Button>
      </S.ButtonWrapper>
    </S.ModalContentWrapper>
  );
};
