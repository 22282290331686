import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as S from './styles';

import { BionicContext } from '../../../BionicContext';
import Icon from '../../atoms/Icon';
import { RatingCardProps } from './types';

export const RatingCard = ({
  comment,
  company,
  title,
  comment_id,
}: RatingCardProps) => {
  const { isLogged } = useContext(BionicContext);
  const navigate = useNavigate();
  const handlePush = () => {
    const commentURL = `/company/${company.company_id}/comment/${comment_id}`;
    const ratingURL = `/company/${company.company_id}/ratings`;
    navigate(isLogged ? commentURL : ratingURL);
  };

  return (
    <S.RatingCard onClick={handlePush}>
      <h3>{title}</h3>
      <span>{comment}</span>
      <S.InformationWrapper>
        <S.CompanyWrapper>
          <S.AvatarIconWrapper>
            <Icon type="avatar" />
          </S.AvatarIconWrapper>
          <h5>{company.company_name}</h5>
        </S.CompanyWrapper>
        <S.RatingCountWrapper>
          <div>
            <Icon type="like" />
            <span>{company.like}</span>
          </div>
          <div>
            <Icon type="dislike" />
            <span>{company.dislike}</span>
          </div>
        </S.RatingCountWrapper>
      </S.InformationWrapper>
    </S.RatingCard>
  );
};
