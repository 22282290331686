import styled from 'styled-components';
import { ButtonProps } from './types';

const colorMap: { [key: string]: string } = {
  'white': 'var(--white)',
  'green': 'var(--green)',
  'dark-green': 'var(--dark-green-1)',
  'black': 'var(--black)',
};

export const Button = styled.button<ButtonProps>`
  border: none;
  font-size: 15px;
  font-weight: 500;
  padding: 12px 24px;
  border-radius: 6px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${(props) => colorMap[props.background]};
  color: ${(props) => colorMap[props.color]};
`;

export const LoadingButtonRoot = styled(Button)`
  &.loading {
    pointer-events: none;
    color: transparent;
    user-select: none;

    &:hover {
      color: transparent;
    }

    &:active,
    &:active:hover {
      color: transparent;
    }
  }

  .loading-spin {
    & > div {
      position: absolute;
      left: calc(50% - 10px);
      top: calc(50% - 10px);
    }
  }
`;
