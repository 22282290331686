import styled from 'styled-components';
import { Button } from '../../atoms/Button';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const RegisterButton = styled(Button)`
  margin-top: 8px;
`;
