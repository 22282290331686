import * as S from './styles';

import { Button } from '../../atoms/Button';
import { TwoButtonDefaultProps } from './types';

export const TwoButtonDefault = ({
  firstButtonLabel,
  secondButtonLabel,
  firstButtonOnClick,
  secondButtonOnClick,
  className,
}: TwoButtonDefaultProps) => {
  return (
    <S.TwoButtonDefaultWrapper className={className}>
      <Button
        className="first-button"
        onClick={firstButtonOnClick}
        color="green"
        background="white"
      >
        {firstButtonLabel}
      </Button>
      <Button
        className="second-button"
        onClick={secondButtonOnClick}
        color="white"
        background="green"
      >
        {secondButtonLabel}
      </Button>
    </S.TwoButtonDefaultWrapper>
  );
};
