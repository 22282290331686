import { memo } from 'react';

import Icon from '../../atoms/Icon';
import * as S from './styles';
import roseane from '../../../assets/roseane.svg';
import joaquim from '../../../assets/joaquim.svg';
import lee from '../../../assets/lee.svg';

export const AboutUs = memo(() => {
  return (
    <S.ContentWrapper>
      <S.IndividualWrapper>
        <S.PersonWrapper>
          <img alt="Rose" src={roseane} />
          <div>
            <p>Rose Borges</p>
            <p>UX UI em construção</p>
          </div>
        </S.PersonWrapper>
        <S.LinkedinWrapper>
          <Icon type="linkedin" />
          <span>https://www.linkedin.com/in/roseane-andreborges/</span>
        </S.LinkedinWrapper>
      </S.IndividualWrapper>
      <S.IndividualWrapper>
        <S.PersonWrapper>
          <img alt="Joaquim" src={joaquim} />
          <div>
            <p>Joaquim Borges</p>
            <p>Software developer Backend</p>
          </div>
        </S.PersonWrapper>
        <S.LinkedinWrapper>
          <Icon type="linkedin" />
          <span>https://www.linkedin.com/in/joaquimborges/</span>
        </S.LinkedinWrapper>
      </S.IndividualWrapper>
      <S.IndividualWrapper>
        <S.PersonWrapper>
          <img alt="Lee" src={lee} />
          <div>
            <p>José Lee</p>
            <p>Software developer Frontend</p>
          </div>
        </S.PersonWrapper>
        <S.LinkedinWrapper>
          <Icon type="linkedin" />
          <span>https://www.linkedin.com/in/jose-carlos-lee/</span>
        </S.LinkedinWrapper>
      </S.IndividualWrapper>
    </S.ContentWrapper>
  );
});
