import styled from 'styled-components';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > h3 {
    margin-bottom: 24px;
  }
`;

export const MenuOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  button {
    align-self: flex-end;
  }

  input,
  textarea {
    margin-bottom: 21px;
  }

  textarea {
    height: 130px;
  }
`;

export const Options = styled.div`
  display: flex;
  border-bottom: 1px solid lightgray;
  padding: 10px;
  gap: 24px;
  cursor: pointer;
  align-items: center;
  transition: filter 0.2s;

  &:hover,
  &:active {
    background-color: lightgray;
    filter: brightness(0.99);
  }

  h3 {
    font-size: 20px;
    font-weight: 500;
    color: var(--black);
  }
`;
