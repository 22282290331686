import { useState } from 'react';

import * as S from './styles';
import { CommentCardProps } from './types';
import { AnswerCommentModal } from '../../organisms/AnswerCommentModal';
import { BiChevronRight } from 'react-icons/bi';

export const CommentCard = ({
  allowedResponse,
  title,
  children,
  comment_id,
}: CommentCardProps) => {
  const [isAnswerCommentModalOpen, setIsAnswerCommentModalOpen] = useState(
    false
  );

  return (
    <>
      <S.ContentWrapper>
        <S.textWrapper>
          <h3>{title}</h3>
          <span>{children}</span>
        </S.textWrapper>
        {allowedResponse && (
          <S.ResponseButton
            className="response-button"
            onClick={() => setIsAnswerCommentModalOpen(true)}
          >
            Responder
            <BiChevronRight size="24px" color="gray" />
          </S.ResponseButton>
        )}
      </S.ContentWrapper>
      <AnswerCommentModal
        isModalOpen={isAnswerCommentModalOpen}
        handleCloseModal={() => setIsAnswerCommentModalOpen(false)}
        commentId={comment_id}
      />
    </>
  );
};
