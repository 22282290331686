import { ChangeEvent } from 'react';

import * as S from './styles';
import { ModalTextAreaProps } from './types';

export const ModalTextArea = ({
  id,
  value,
  onChange,
  label,
  placeholder,
  isRequired,
}: ModalTextAreaProps) => {
  return (
    <S.ModalTextAreaContainer>
      <S.ModalTextAreaLabel isRequired={isRequired} htmlFor={id}>
        {label}
      </S.ModalTextAreaLabel>
      <S.ModalTextAreaWrapper>
        <S.ModalTextArea
          id={id}
          cols={6}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
            onChange(event.currentTarget.value)
          }
          value={value}
          placeholder={placeholder}
        />
      </S.ModalTextAreaWrapper>
    </S.ModalTextAreaContainer>
  );
};

ModalTextArea.defaultProps = {
  isRequired: false,
};
