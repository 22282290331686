import styled from 'styled-components';

export const ShareExperienceWrapper = styled.div`
  display: flex;
  padding: 52px;
  justify-content: space-around;
  max-width: 1440px;
  margin: auto;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 14px;

    & > div {
      max-width: 100%;
    }
  }
`;

export const FindCompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: 35%;

  h1 {
    font-size: 32px;
    font-weight: 500;
    line-height: 44px;
    margin-bottom: 22px;

    @media (max-width: 768px) {
      line-height: 24px;
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    align-items: center;
    width: 100%;
    margin-top: 24px;

    h1 {
      align-self: center;
      font-size: 18px;
      margin-bottom: 4px;
      font-weight: 600;
    }

    label {
      width: 90%;
    }
  }
`;

export const DescriptionCard = styled.div`
  max-width: 30%;
  h2 {
    font-size: 20px;

    @media (max-width: 768px) {
      font-size: 14px;
      margin-top: 20px;
    }
  }
`;
