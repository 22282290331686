import styled from 'styled-components';

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  input {
    margin-bottom: 21px;
  }

  img {
    margin-bottom: 24px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  h3 {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 180% */
    letter-spacing: -0.3px;
  }

  span {
    color: #000;
    text-align: center;
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.16px;
    margin-bottom: 24px;
  }
`;

export const ButtonWrapper = styled.div`
  align-self: flex-end;

  @media (max-width: 768px) {
    &,
    button {
      width: 100%;
    }
  }
`;

export const ResendPasswordSpan = styled.a`
  color: #000;
  text-align: center;
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: 0.12px;
  text-decoration-line: underline;
  margin-bottom: 24px;
  cursor: pointer;
  disabled: true;
`;
