import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { BreadcrumbProps } from './types';

export function Breadcrumb ({ breadcrumbList }: BreadcrumbProps) {
  const navigate = useNavigate();
  const handlePush = (href: string) => {
    navigate(href);
  };

  function handleClick (event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault();
  }

  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        {breadcrumbList.map(({ ariaLabel, href, label }, index) =>
          href ? (
            <Link
              underline="hover"
              color="inherit"
              href={href}
              aria-label={ariaLabel}
              key={index}
              onClick={() => handlePush(href)}
            >
              {label}
            </Link>
          ) : (
            <Typography color="text.primary" aria-label={ariaLabel} key={index}>
              {label}
            </Typography>
          )
        )}
      </Breadcrumbs>
    </div>
  );
}
