import styled from 'styled-components';
import { ModalComponent } from '../../molecules/Modal';
import { TwoButtonDefault } from '../../molecules/TwoButtonDefault';

export const Modal = styled(ModalComponent)`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 20px 40px;

  img {
    align-self: center;
  }

  @media (max-width: 768px) {
    padding: 14px 21px;
    & > div:last-child {
      display: flex;
      flex-direction: column;
      height: unset;
      gap: 24px;
      border: none;
      margin-top: 24px;
    }
  }
`;

export const RegisterButtons = styled(TwoButtonDefault)`
  border-radius: unset;
  width: 100%;
  margin-top: 24px;
`;

export const Title = styled.h2`
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.36px;
`;
