import styled from 'styled-components';
import { colorMap } from '../../../styles/global';

interface InputProps {
  background: string;
  color: string;
}

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
`;

export const Input = styled.input<InputProps>`
  width: 100%;
  border: 0;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  padding: 15px;

  background: ${(props) => colorMap[props.background]};

  :focus,
  input:focus {
    outline: none;
  }

  &::placeholder {
    color: ${(props) => colorMap[props.color]};
  }

  @media (max-width: 768px) {
    padding: 12px 15px 12px 0;
  }
`;

export const InputLabel = styled.label`
  margin-bottom: 10px;
`;
