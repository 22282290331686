import { useState } from 'react';

import * as S from './styles';

import { ListCompanyRatingCardProps } from './types';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
import { CompanyRatingCard } from '../../molecules/CompanyRatingCard';

export const ListCompanyRatingCard = ({
  search,
  companyRatingList,
}: ListCompanyRatingCardProps) => {
  const [scrollX, setScrollX] = useState(0);

  const onClickLeftArrow = () => {
    const scrollWidth = window.innerWidth > 1440 ? 1440 : window.innerWidth;
    let x = scrollX + Math.round(scrollWidth / 2);
    if (x > 0) {
      x = 0;
    }
    setScrollX(x);
  };
  const onClickRightArrow = () => {
    const scrollWidth = window.innerWidth > 1440 ? 1440 : window.innerWidth;
    let x = scrollX - Math.round(scrollWidth / 2);
    let listW = companyRatingList.length * 328;

    if (scrollWidth - listW > x) {
      x = scrollWidth - listW - 80;
    }

    setScrollX(x);
  };

  return (
    <S.Wrapper>
      <h3>Principais resultados para "{search}"</h3>
      <S.Container>
        {companyRatingList.length * 328 > 1440 && (
          <S.ChevronWrapper>
            <HiOutlineChevronLeft
              className="carousel-left"
              size="72px"
              color="#FFF"
              onClick={onClickLeftArrow}
            />
            <HiOutlineChevronRight
              className="carousel-right"
              size="72px"
              color="#FFF"
              onClick={onClickRightArrow}
            />
          </S.ChevronWrapper>
        )}
        <S.ListCompanyRatingCardWrapper>
          <S.ListCompanyRatingCard
            marginLeft={scrollX}
            width={companyRatingList.length * 328}
          >
            {companyRatingList.map(
              ({ address, name, dislike, likes, cnpj, category }, index) => (
                <CompanyRatingCard
                  key={index}
                  address={address}
                  name={name}
                  dislike={dislike}
                  likes={likes}
                  cnpj={cnpj}
                  category={category}
                />
              )
            )}
          </S.ListCompanyRatingCard>
        </S.ListCompanyRatingCardWrapper>
      </S.Container>
    </S.Wrapper>
  );
};
