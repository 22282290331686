import { useContext, useEffect, useState } from 'react';
import { BionicContext } from '../../../BionicContext';
import { Button } from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import { ModalInput } from '../../atoms/ModalInput';
import { ModalTextArea } from '../../atoms/ModalTextArea';
import { RatingHearts } from '../../atoms/RatingHearts';
import { ModalComponent } from '../../molecules/Modal';
import Switch from '@mui/material/Switch';
import * as S from './styles';
import { RateCompanyModalProps } from './types';

export const RateCompanyModal = ({
  companyId,
  isModalOpen,
  handleCloseModal,
}: RateCompanyModalProps) => {
  const { postRateCompany } = useContext(BionicContext);
  const [isLoading, setIsLoading] = useState(false);
  const [rateTitle, setRateTitle] = useState('');
  const [experience, setExperience] = useState('');
  const [liked, setLiked] = useState(true);
  const [rateHearts, setRateHearts] = useState(2);

  useEffect(() => {
    setRateTitle('');
    setExperience('');
    setLiked(true);
  }, [isModalOpen]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await postRateCompany({
        companyId,
        title: rateTitle,
        comment: experience,
        ratings: rateHearts,
        like: liked ? 1 : 0,
        dislike: liked ? 0 : 1,
      });
      handleCloseModal();
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLiked(event.target.checked);
  };

  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
    >
      <S.ModalContentWrapper>
        <S.TitleWrapper>
          <h3>Avaliar empresa</h3>
        </S.TitleWrapper>
        <S.RateHeartsWrapper>
          <S.LikeDislikeWrapper>
            <div>
              <p>Como foi a sua experiência?</p>
              {liked ? <Icon type="like" /> : <Icon type="dislike" />}
            </div>
            <div>
              <span>Não gostei</span>
              <Switch
                checked={liked}
                onChange={handleSwitch}
                inputProps={{
                  'aria-label': `${liked ? 'gostei' : 'não gostei'}`,
                }}
              />
              <span>Gostei</span>
            </div>
          </S.LikeDislikeWrapper>
          <RatingHearts
            value={rateHearts}
            size="large"
            setValue={setRateHearts}
            ratingHeartsLabel="O quanto você recomenda este lugar?"
          />
        </S.RateHeartsWrapper>
        <ModalInput
          id="rate-title"
          value={rateTitle}
          onChange={setRateTitle}
          placeholder="Título da experiência"
          label="Escreva um título"
        />
        <ModalTextArea
          id="experience"
          value={experience}
          onChange={setExperience}
          placeholder="Relate aqui sua experiência"
          label="Descreva sua experiência"
        />
        <Button
          background="green"
          color="white"
          className="send-button"
          loading={isLoading}
          onClick={() => handleSubmit()}
          disabled={!(rateTitle && experience && rateHearts)}
        >
          Enviar
        </Button>
      </S.ModalContentWrapper>
    </ModalComponent>
  );
};
