import styled from 'styled-components';

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  button {
    align-self: flex-end;
  }

  input,
  textarea {
    margin-bottom: 21px;
  }

  textarea {
    height: 130px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  h3 {
    font-size: 20px;
    font-weight: 700;
    color: var(--black);
  }

  @media (max-width: 768px) {
    align-items: center;
  }
`;
