import styled from 'styled-components';

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  input {
    margin-bottom: 21px;
  }
`;

interface FeedbackSpanProps {
  show: boolean;
}

export const FeedbackSpan = styled.span<FeedbackSpanProps>`
  font-size: 14px;
  font-weight: 400;
  color: red;

  display: ${({ show }) => (show ? 'block' : 'none')};
`;

export const ResendPasswordSpan = styled.a`
  color: #000;
  text-align: center;
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: 0.12px;
  text-decoration-line: underline;
  margin-bottom: 24px;
  cursor: pointer;
  disabled: true;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-bottom: 30px;

  h3 {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.3px;
  }

  span {
    color: #000;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.14px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 40px;
  align-self: flex-end;

  @media (max-width: 768px) {
    &,
    button {
      width: 100%;
    }
  }
`;
