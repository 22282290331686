import styled from 'styled-components';

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  input {
    margin-bottom: 21px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  h3 {
    font-size: 18px;
    font-weight: 400;
    color: var(--black);
    margin-bottom: 10px;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    color: #495b4b;
    margin-bottom: 20px;
  }

  & > div {
    display: flex;
  }
`;

export const AvatarIconWrapper = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #bdbdbd;
  justify-content: center;
  align-items: center;

  svg {
    width: 14px;
    height: 14px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    border: 1px solid #9e9e9e;
    padding: 6px 12px;
  }
`;
