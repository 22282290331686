import styled from 'styled-components';

export const LogoDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 0 22px;
  gap: 10px;
  cursor: pointer;

  h2 {
    white-space: nowrap;
  }

  @media (max-width: 768px) {
    flex: 1;
  }
`;
