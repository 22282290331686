import { useEffect, useState } from 'react';

export function useDebounce<T> (value: T, delay = 400): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export const getAuthCookie = () => {
  const cookies = document.cookie.split(';');

  if (cookies[0] === '') return null;
  const cookieByName = cookies
    .filter((item) => item.trim().startsWith('authorization'))[0]
    .trim();
  const cookieByNameValue = cookieByName.split('=')[1];

  return cookieByNameValue;
};

export const isMobileDevice = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
