import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { SpinProps } from './types';

const Spin = ({ size }: SpinProps) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress color="success" size={size} />
    </Box>
  );
};

export default Spin;
