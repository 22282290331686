import { memo } from 'react';

import * as S from './styles';

export const AboutUsDescription = memo(() => {
  return (
    <S.ContentWrapper>
      <p>
        Queremos contribuir para a construção de ambientes seguros e agradáveis
        para todas as pessoas.
      </p>
      <p>
        Este espaço destina-se ao compartilhamento de experiências de
        discriminação e racismo em estabelecimentos como lojas de varejo,
        restaurantes, supermercados, ou qualquer ambiente de suas relações de
        consumo.
      </p>
      <p>
        Esperamos que a partir dessas informações pessoas pretas possam escolher
        onde usar seu dinheiro considerando a experiência.
      </p>
    </S.ContentWrapper>
  );
});
