import { useContext, useEffect, useState } from 'react';
import { BionicContext } from '../../../BionicContext';
import { isMobileDevice } from '../../../utils';
import { Button } from '../../atoms/Button';
import { ModalInput } from '../../atoms/ModalInput';
import { ForgotPasswordScreen } from '../../molecules/ForgotPasswordScreen';
import { FormButtonSwitcher } from '../../molecules/FormButtonSwitcher';
import { InsertCodeScreen } from '../../molecules/InsertCodeScreen';
import { ModalComponent } from '../../molecules/Modal';
import * as S from './styles';
import { SignInModalProps, SignInMode } from './types';

export const SignInModal = ({
  isModalOpen,
  handleCloseModal,
}: SignInModalProps) => {
  const { postSignIn } = useContext(BionicContext);
  const [isLoading, setIsLoading] = useState(false);
  const [mailValue, setMailValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [signInMode, setSignInMode] = useState<SignInMode>('user');
  const [modalStep, setModalStep] = useState(1);
  const [identifier, setIdentifier] = useState('');

  useEffect(() => {
    setMailValue('');
    setPasswordValue('');
    setModalStep(1);
  }, [signInMode, isModalOpen]);

  const isUser = () => {
    return signInMode === 'user';
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await postSignIn({
        email: mailValue,
        password: passwordValue,
      });

      handleCloseModal();
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
    >
      {modalStep === 1 && (
        <S.ModalContentWrapper>
          <S.TitleWrapper>
            <h3>Entrar {isMobileDevice() && 'com e-mail e senha'}</h3>
            {!isMobileDevice() && (
              <FormButtonSwitcher
                selectedOption={isUser() ? 'first' : 'second'}
                firstButtonLabel="Cliente"
                secondButtonLabel="Empresa"
                firstButtonOnClick={() => setSignInMode('user')}
                secondButtonOnClick={() => setSignInMode('company')}
              />
            )}
          </S.TitleWrapper>
          <ModalInput
            id="mail"
            value={mailValue}
            onChange={setMailValue}
            isRequired={true}
            placeholder="Nome@email.com"
            label="E-mail"
          />
          <ModalInput
            type="password"
            id="senha"
            value={passwordValue}
            onChange={setPasswordValue}
            isRequired={true}
            placeholder="Digite a senha."
            label="Senha"
          />
          <S.ForgotPasswordSpan onClick={() => setModalStep(2)}>
            Esqueci a senha.
          </S.ForgotPasswordSpan>
          <Button
            background="green"
            color="white"
            className="sign-button"
            loading={isLoading}
            onClick={() => handleSubmit()}
            disabled={!mailValue || !passwordValue}
          >
            Entrar
            {!isMobileDevice() &&
              (isUser() ? ' como cliente' : ' como empresa')}
          </Button>
        </S.ModalContentWrapper>
      )}
      {modalStep === 2 && (
        <InsertCodeScreen
          isModalOpen={isModalOpen}
          setNextStep={() => setModalStep(3)}
          identifier={identifier}
          setIdentifier={setIdentifier}
        />
      )}
      {modalStep === 3 && (
        <ForgotPasswordScreen
          identifier={identifier}
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
        />
      )}
    </ModalComponent>
  );
};
