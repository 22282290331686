import { useContext, useEffect, useState } from 'react';
import { BionicContext } from '../../../BionicContext';
import { Button } from '../../atoms/Button';
import { ModalInput } from '../../atoms/ModalInput';
import { ModalTextArea } from '../../atoms/ModalTextArea';
import { ModalComponent } from '../../molecules/Modal';
import * as S from './styles';
import { SupportModalProps } from './types';

export const SupportModal = ({
  isModalOpen,
  handleCloseModal,
}: SupportModalProps) => {
  const { postContactSupport } = useContext(BionicContext);
  const [isLoading, setIsLoading] = useState(false);
  const [mailValue, setMailValue] = useState('');
  const [name, setName] = useState('');
  const [contactSupportValue, setContactSupportValue] = useState('');

  useEffect(() => {
    setMailValue('');
    setContactSupportValue('');
  }, [isModalOpen]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await postContactSupport({
        name,
        email: mailValue,
        body: contactSupportValue,
      });
      handleCloseModal();
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
    >
      <S.ModalContentWrapper>
        <S.TitleWrapper>
          <h3>Suporte</h3>
        </S.TitleWrapper>
        <ModalInput
          id="name"
          value={name}
          onChange={setName}
          placeholder="Digite seu nome"
          label="Como prefere ser chamado?"
        />
        <ModalInput
          id="mail"
          value={mailValue}
          onChange={setMailValue}
          placeholder="Nome@email.com"
          label="Digite seu E-mail"
        />
        <ModalTextArea
          id="support"
          value={contactSupportValue}
          onChange={setContactSupportValue}
          placeholder="Escreva aqui sua mensagem."
          label="Precisa falar conosco?"
        />
        <Button
          background="green"
          color="white"
          className="send-button"
          loading={isLoading}
          onClick={() => handleSubmit()}
          disabled={!(mailValue && contactSupportValue)}
        >
          Enviar
        </Button>
      </S.ModalContentWrapper>
    </ModalComponent>
  );
};
