import { useContext } from 'react';
import { InputWithIcon } from '../../molecules/InputWithIcon';
import * as S from './styles';
import { LoggedShareExperienceProps } from './types';
import { BionicContext } from '../../../BionicContext';

export const LoggedShareExperience = ({
  value,
  onChange,
  isNewUser,
}: LoggedShareExperienceProps) => {
  const { userName } = useContext(BionicContext);

  return (
    <S.LoggedShareExperienceWrapper isNewUser={isNewUser}>
      <S.DescriptionWrapper>
        <h3>
          {isNewUser
            ? 'Sua presença nos fortalece'
            : 'Olá, ' +
              userName.charAt(0).toUpperCase() +
              userName.slice(1) +
              '!'}
        </h3>
        <h3>
          {isNewUser
            ? 'Vamos começar?'
            : 'Veja suas experiências compartilhadas'}
        </h3>
      </S.DescriptionWrapper>
      <S.FindCompanyWrapper isNewUser={isNewUser}>
        <h1>
          {isNewUser
            ? 'Compartilhe sua primeira experiência'
            : 'Conta outra aqui'}
        </h1>
        <InputWithIcon
          id="menu-input"
          type="search"
          placeholder="Digite o nome da empresa ou CNPJ"
          label=""
          value={value}
          onChange={onChange}
        />
        {!value && isNewUser && (
          <>
            <h4 className="first">Aguardando curiosamente</h4>
            <h4>
              É possível buscar o lugar que você procura através do nome ou
              CNPJ.
            </h4>
          </>
        )}
      </S.FindCompanyWrapper>
    </S.LoggedShareExperienceWrapper>
  );
};

LoggedShareExperience.defaultProps = {
  background: 'light-green-2',
  color: 'black',
};
