import styled from 'styled-components';

interface LoggedShareExperienceWrapperProps {
  isNewUser: boolean;
}

export const LoggedShareExperienceWrapper = styled.div<
  LoggedShareExperienceWrapperProps
>`
  display: flex;
  padding: 52px;
  justify-content: space-around;
  max-width: 1440px;
  margin: auto;

  ${(props) => props.isNewUser && 'flex-direction: column;'}

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 14px;
    max-width: 100%;

    & > div {
      max-width: 100%;
    }
  }
`;

export const FindCompanyWrapper = styled.div<LoggedShareExperienceWrapperProps>`
  display: flex;
  flex-direction: column;
  width: fit-content;
  width: 30%;

  ${(props) =>
    props.isNewUser &&
    `
    align-self: center;
    width: fit-content;
    margin-top: 60px;
  `}

  h1 {
    font-size: 30px;
    font-weight: 500;
    line-height: 44px;
    margin-bottom: 22px;
    text-align: center;

    @media (max-width: 768px) {
      line-height: 24px;
      text-align: center;
    }
  }

  h4 {
    font-size: 14px;
    font-weight: 600;
    text-align: center;

    &.first {
      margin: 64px 0 24px 0;
    }
  }

  @media (max-width: 768px) {
    align-items: center;
    width: 100%;
    margin-top: 24px;

    h1 {
      align-self: center;
      font-size: 18px;
      margin-bottom: 4px;
      font-weight: 600;
    }

    label {
      width: 90%;
    }
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 30px;
    line-height: 30px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    @media (max-width: 768px) {
      font-size: 14px;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 768px) {
    font-size: 14px;
    margin: 14px;
  }
`;
