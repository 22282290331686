import styled from 'styled-components';
import { colorMap } from '../../../styles/global';

interface InputWithIconProps {
  background: string;
}

export const InputWithIconWrapper = styled.label<InputWithIconProps>`
  display: flex;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;

  background: ${(props) => colorMap[props.background]};

  svg {
    margin-left: 20px;
    margin-right: 12px;

    @media (max-width: 768px) {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
`;
