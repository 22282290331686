import { InputWithIcon } from '../../molecules/InputWithIcon';
import * as S from './styles';
import { ShareExperienceProps } from './types';

export const ShareExperience = ({ value, onChange }: ShareExperienceProps) => {
  return (
    <S.ShareExperienceWrapper>
      <S.FindCompanyWrapper>
        <h1>Vamos lá, compartilhe uma experiência</h1>
        <InputWithIcon
          id="menu-input"
          type="search"
          placeholder="Digite o nome da empresa ou CNPJ"
          label=""
          value={value}
          onChange={onChange}
        />
      </S.FindCompanyWrapper>
      <S.DescriptionCard>
        <h2>
          Este espaço foi especialmente pensado para compartilharmos
          experiências motivadas por racismo vivenciadas em lojas,
          supermercados, restaurantes e outros lugares das nossas relações de
          consumo.
        </h2>
      </S.DescriptionCard>
    </S.ShareExperienceWrapper>
  );
};

ShareExperience.defaultProps = {
  background: 'light-green-2',
  color: 'black',
};
