import styled from 'styled-components';

export const RatingCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px;
  gap: 8px;
  border-radius: 10px;
  background: var(--light-green-1);
  overflow: hidden;
  cursor: pointer;

  h3 {
    font-size: 20px;
  }

  & > span {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  @media (min-width: 768px) {
    padding: 10px;
    transform: scale(0.9);
    transition: all ease 0.2s;
    min-width: 328px;

    &:hover {
      transform: scale(1);
    }
  }
`;

export const AvatarIconWrapper = styled.div`
  display: flex;
  width: 47px;
  height: 44px;
  border-radius: 4px;
  background: #bdbdbd;
  justify-content: center;
  align-items: center;
`;

export const InformationWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex: 1;
`;

export const CompanyWrapper = styled.div`
  display: flex;
  align-items: center;
  h5 {
    margin-left: 10px;
  }
`;

export const RatingCountWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    align-items: flex-start;

    span {
      margin-left: 5px;
    }
  }
`;
