import { ChangeEvent } from 'react';
import * as S from './styles';
import { InputProps } from './types';

export const Input = ({
  id,
  background,
  color,
  label,
  placeholder,
  value,
  onChange,
}: InputProps) => {
  return (
    <S.InputWrapper>
      {label && <S.InputLabel htmlFor={id}>{label}</S.InputLabel>}
      <S.Input
        id={id}
        placeholder={placeholder}
        background={background}
        color={color}
        value={value}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChange(event.currentTarget.value)
        }
      />
    </S.InputWrapper>
  );
};

Input.defaultProps = {
  background: 'light-green-2',
  color: 'black',
};
