import styled from 'styled-components';
import { Button } from '../../atoms/Button';

export const CompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  & > h4 {
    margin: 28px 0 54px;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;

    & > h4 {
      font-size: 16px;
      margin: 14px;
      text-align: center;
      font-weight: 600;
    }
  }
`;

export const CompanyDescriptionWrapper = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 500;
  color: #000000b0;
  align-items: flex-start;
  justify-content: flex-start;

  h4 {
    color: var(--black);
    margin-top: 12px;
  }
`;

export const CompanyInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    flex: 1;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px 14px;
    gap: 20px;
  }
`;

export const RateCompanyButton = styled(Button)`
  padding: 12px 48px;
  font-size: 22px;
  border: 1px solid var(--white);
  width: fit-content;
  margin-top: 40px;
  align-self: flex-end;

  @media (max-width: 768px) {
    align-self: center;
    width: 100%;
  }
`;

export const CompanyLogoDiv = styled.div`
  background: #fafafa;
  padding: 26px 48px;
  border-radius: 8px;
  margin-right: 5px;
  align-items: center;
  justify-content: center;
  height: fit-content;
  font-size: 18px;
  font-weight: 500;
`;

export const RateCompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 768px) {
    align-self: flex-start;
    width: 100%;
  }
`;

export const RightContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
