import Icon from '../../atoms/Icon';
import { Input } from '../../atoms/Input';
import * as S from './styles';
import { InputWithIconProps } from './types';

export const InputWithIcon = ({
  id,
  background,
  color,
  label,
  placeholder,
  type,
  value,
  onChange,
}: InputWithIconProps) => {
  return (
    <S.InputWithIconWrapper htmlFor={id} background={background}>
      <Icon type={type} />
      <Input
        id={id}
        placeholder={placeholder}
        label={label}
        background={background}
        color={color}
        value={value}
        onChange={onChange}
      />
    </S.InputWithIconWrapper>
  );
};

InputWithIcon.defaultProps = {
  background: 'light-green-2',
  color: 'black',
};
