import styled from 'styled-components';

export const Container = styled.header`
  background-color: var(--light-green-1);
`;

export const Content = styled.div`
  max-width: 1440px;
  margin: auto;
  padding: 7px 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > svg {
    cursor: pointer;

    &:first-child {
      display: none;
    }
  }

  @media (max-width: 768px) {
    padding: 4px 14px;
    justify-content: unset;

    & > svg:first-child {
      display: unset;
    }
  }
`;
