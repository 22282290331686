import { useContext, useState } from 'react';

import * as S from './styles';

import { MenuItems } from '../../molecules/MenuItems';
import { TwoButtonDefault } from '../../molecules/TwoButtonDefault';
import { PageLogo } from '../../molecules/PageLogo';
import { SignInModal } from '../../organisms/SignInModal';
import { SignUpModal } from '../../organisms/SignUpModal';
import { BionicContext } from '../../../BionicContext';
import Icon from '../../atoms/Icon';
import { MenuItemsProps } from '../../molecules/MenuItems/types';
import { UserInfoModal } from '../../organisms/UserInfoModal';
import { CompanyInfoModal } from '../../organisms/CompanyInfoModal';
import { MenuModal } from '../../organisms/MenuModal';
import { AiOutlineMenu } from 'react-icons/ai';

export const Header = ({ currentPage }: MenuItemsProps) => {
  const { isLogged, isCompany } = useContext(BionicContext);
  const [isMenuModalOpen, setIsMenuModalOpen] = useState(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [isUserInfoModalOpen, setIsUserInfoModalOpen] = useState(false);
  const [isCompanyInfoModalOpen, setIsCompanyInfoModalOpen] = useState(false);
  return (
    <>
      <S.Container>
        <S.Content>
          <AiOutlineMenu size="40px" onClick={() => setIsMenuModalOpen(true)} />
          <PageLogo />
          <MenuItems currentPage={currentPage} />
          {isLogged ? (
            <Icon
              type="logged"
              onClick={() =>
                isCompany
                  ? setIsCompanyInfoModalOpen(true)
                  : setIsUserInfoModalOpen(true)
              }
            />
          ) : (
            <TwoButtonDefault
              firstButtonLabel="Entrar"
              secondButtonLabel="Registrar"
              firstButtonOnClick={() => setIsSignInModalOpen(true)}
              secondButtonOnClick={() => setIsSignUpModalOpen(true)}
            />
          )}
        </S.Content>
      </S.Container>
      <MenuModal
        isModalOpen={isMenuModalOpen}
        handleCloseModal={() => setIsMenuModalOpen(false)}
        firstButtonOnClick={() => setIsSignInModalOpen(true)}
        secondButtonOnClick={() => setIsSignUpModalOpen(true)}
      />
      {isLogged ? (
        <>
          <UserInfoModal
            isModalOpen={isUserInfoModalOpen}
            handleCloseModal={() => setIsUserInfoModalOpen(false)}
          />
          <CompanyInfoModal
            isModalOpen={isCompanyInfoModalOpen}
            handleCloseModal={() => setIsCompanyInfoModalOpen(false)}
          />
        </>
      ) : (
        <>
          <SignInModal
            isModalOpen={isSignInModalOpen}
            handleCloseModal={() => setIsSignInModalOpen(false)}
          />
          <SignUpModal
            isModalOpen={isSignUpModalOpen}
            handleCloseModal={() => setIsSignUpModalOpen(false)}
          />
        </>
      )}
    </>
  );
};
