import styled from 'styled-components';

export const MenuItems = styled.ul`
  display: flex;
  gap: 38px;
  flex: 1;
  justify-content: flex-start;
  list-style-type: none;
  align-items: center;
  padding: 0 19px 0 84px;

  @media (max-width: 1216px) {
    gap: 16px;
    padding: 0 18px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const MenuListItem = styled.li`
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
`;
