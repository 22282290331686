import styled from 'styled-components';

export const FormButtonSwitcherWrapper = styled.div`
  display: flex;
  border: 1px solid var(--green);
  border-radius: 6px;
  overflow: hidden;
  width: fit-content;

  .first-button {
      flex: 1;
      border-radius: 0 6px 6px 0;
      z-index: 1;

      &.selected {
        border: 1px solid var(--green);
        margin-right: -5px;
        z-index: 2;
      }
    }

    .second-button {
      flex: 1.06;
      width: 106px;
      z-index: 1;

      &.selected {
        border: 1px solid var(--green);
        margin-left: -5px;
        z-index: 2;
      }
    }
  }
`;
