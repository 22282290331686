import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as S from './styles';

import { Header } from '../../templates/Header';
import { ListRatingCard } from '../../organisms/ListRatingCard';
import { ShareExperience } from '../../organisms/ShareExperience';
import { BionicContext } from '../../../BionicContext';
import { LoggedShareExperience } from '../../organisms/LoggedShareExperience';
import { Footer } from '../../molecules/Footer';
import { ListCompanyRatingCard } from '../../organisms/ListCompanyRatingCard';

import { useDebounce } from '../../../utils';
import Spin from '../../atoms/Spin';
import SnackBar from '../../atoms/SnackBar';
import { RegisterCompanyModal } from '../../organisms/RegisterCompanyModal';
import { RegisterCompany } from '../../molecules/RegisterCompany';
import { RatingCardProps } from '../../molecules/RatingCard/types';

export const Home = () => {
  const {
    getRatingList,
    searchCompanies,
    userId,
    isCompany,
    setSearchCompanies,
    isLogged,
    isLoading,
    getSearchCompanies,
    handleCloseSnackBar,
    snackBarAlertProps,
  } = useContext(BionicContext);
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [ratings, setRatings] = useState([] as RatingCardProps[]);
  const [hasAttemptedSearch, setHasAttemptedSearch] = useState(false);
  const [isRegisterCompanyModalOpen, setIsRegisterCompanyModalOpen] = useState(
    false
  );

  const debouncedValue = useDebounce<string>(search);

  useEffect(() => {
    getSearchCompanies(search);
    if (search) setHasAttemptedSearch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    if (!search) {
      setSearchCompanies([]);
      setHasAttemptedSearch(false);
    }
  }, [search, setSearchCompanies]);

  const handleRatingPush = useCallback(() => {
    navigate(`/company/${userId}/ratings`);
  }, [navigate, userId]);

  useEffect(() => {
    if (isLogged && isCompany) {
      handleRatingPush();
    } else {
      const getRatings = async () => {
        const data = await getRatingList();
        setRatings(data);
      };
      getRatings();
    }
  }, [getRatingList, handleRatingPush, isCompany, isLogged]);

  return (
    <>
      <Header />
      <div id="main-content">
        {isLogged ? (
          <LoggedShareExperience
            value={search}
            onChange={setSearch}
            isNewUser={ratings.length === 0}
          />
        ) : (
          <ShareExperience value={search} onChange={setSearch} />
        )}
        {isLoading && (
          <S.SpinWrapper>
            <Spin size={60} />
          </S.SpinWrapper>
        )}
        {search && searchCompanies.length >= 1 && !isLoading && (
          <ListCompanyRatingCard
            companyRatingList={searchCompanies}
            search={search}
          />
        )}
        {search &&
          searchCompanies.length === 0 &&
          !isLoading &&
          hasAttemptedSearch && (
            <RegisterCompany
              onClick={() => setIsRegisterCompanyModalOpen(true)}
            />
          )}
        {!search && !isLoading && <ListRatingCard ratingList={ratings} />}
      </div>
      <RegisterCompanyModal
        isModalOpen={isRegisterCompanyModalOpen}
        handleCloseModal={() => setIsRegisterCompanyModalOpen(false)}
      />
      {snackBarAlertProps.show && (
        <SnackBar
          type={snackBarAlertProps.type}
          onClose={handleCloseSnackBar}
          open={snackBarAlertProps.show}
        >
          {snackBarAlertProps.message}
        </SnackBar>
      )}
      <Footer />
    </>
  );
};

export default Home;
