import { useContext, useEffect, useState } from 'react';
import { BionicContext } from '../../../BionicContext';
import { Button } from '../../atoms/Button';
import { ModalTextArea } from '../../atoms/ModalTextArea';
import { ModalComponent } from '../../molecules/Modal';
import * as S from './styles';
import { AnswerCommentModalProps } from './types';

export const AnswerCommentModal = ({
  isModalOpen,
  handleCloseModal,
  commentId,
}: AnswerCommentModalProps) => {
  const { postAnswerComment } = useContext(BionicContext);
  const [answer, setAnswer] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setAnswer('');
  }, [isModalOpen]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await postAnswerComment({
        commentId,
        answer,
      });
      handleCloseModal();
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
    >
      <S.ModalContentWrapper>
        <S.TitleWrapper>
          <h3>Responder a esta avaliação</h3>
        </S.TitleWrapper>
        <ModalTextArea
          id="answer"
          value={answer}
          onChange={setAnswer}
          placeholder="Escreva sua resposta."
          label="Resposta"
        />
        <Button
          background="green"
          color="white"
          className="send-button"
          loading={isLoading}
          onClick={() => handleSubmit()}
          disabled={!answer}
        >
          Enviar
        </Button>
      </S.ModalContentWrapper>
    </ModalComponent>
  );
};
