import { useNavigate } from 'react-router-dom';
import Icon from '../../atoms/Icon';
import { BionicContext } from '../../../BionicContext';
import * as S from './styles';
import { useContext } from 'react';

export const PageLogo = () => {
  const navigate = useNavigate();
  const { isLogged, isCompany, userId } = useContext(BionicContext);

  const handleHomePush = () => {
    navigate('/');
  };

  const handleRatingPush = () => {
    navigate(`/company/${userId}/ratings`);
  };

  const redirectUser = () => {
    if (isLogged && isCompany) {
      handleRatingPush();
    } else {
      handleHomePush();
    }
  };

  return (
    <S.LogoDiv onClick={redirectUser}>
      <Icon type="bionic" />
      <h2>Bionic Black</h2>
    </S.LogoDiv>
  );
};
