import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 1440px;
  margin: auto auto 34px;

  & > h3 {
    margin-left: 40px;
    margin-bottom: 40px;
    font-size: 20px;
    font-weight: 500;
  }
`;

export const Container = styled.div`
  display: flex;
  position: relative;

  &:hover {
    .carousel-left {
      opacity: 1;
    }

    .carousel-right {
      opacity: 1;
    }
  }

  .carousel-left,
  .carousel-right {
    position: absolute;
    width: 40px;
    height: 161px;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    opacity: 0;
    transition: all ease 0.5s;
  }

  .carousel-left {
    left: 0;
  }

  .carousel-right {
    right: 0;
  }
`;

export const ChevronWrapper = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

interface ListCompanyRatingCardProps {
  width: number;
  marginLeft: number;
}

export const ListCompanyRatingCard = styled.div<ListCompanyRatingCardProps>`
  display: flex;
  transition: all ease 0.5s;
  margin-left: ${(props) => `${props.marginLeft}px`};
  width: ${(props) => `${props.width}px`};

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    width: 100%;
  }
`;

export const ListCompanyRatingCardWrapper = styled.div`
  overflow-x: hidden;
  padding-left: 40px;

  @media (max-width: 768px) {
    width: 100%;
    overflow-x: unset;
    padding-left: 0;
  }
`;
