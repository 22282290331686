import * as S from './styles';

import { RatingHeartsProps } from './types';
import { Rating } from '@mui/material';
import { Favorite, FavoriteBorder } from '@mui/icons-material';

export const RatingHearts = ({
  precision,
  max,
  readOnly,
  size,
  value,
  ratingHeartsLabel,
  setValue,
}: RatingHeartsProps) => {
  return (
    <S.RatingHeartsWrapper>
      <p>{ratingHeartsLabel}</p>
      <Rating
        name="rating"
        getLabelText={(value: number) =>
          `${value} Heart${value !== 1 ? 's' : ''}`
        }
        max={max}
        precision={precision}
        readOnly={readOnly}
        size={size}
        value={value}
        icon={<Favorite fontSize="inherit" sx={{ color: 'black' }} />}
        emptyIcon={
          <FavoriteBorder fontSize="inherit" sx={{ color: '#BDBDBD' }} />
        }
        {...setValue && {
          onChange: (event: React.SyntheticEvent) =>
            setValue(Number((event.target as HTMLInputElement).value)),
        }}
      />
    </S.RatingHeartsWrapper>
  );
};

RatingHearts.defaultProps = {
  precision: 0.5,
  max: 5,
  readOnly: false,
  size: 'medium',
};
