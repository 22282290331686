import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { SnackBarProps } from './types';

const SnackBar = ({
  open,
  autoHideDuration,
  onClose,
  type,
  children,
}: SnackBarProps) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Alert onClose={onClose} severity={type} sx={{ width: '100%' }}>
        {children}
      </Alert>
    </Snackbar>
  );
};

SnackBar.defaultProps = {
  autoHideDuration: 4000,
};

export default SnackBar;
