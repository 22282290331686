import { useContext, useEffect, useState } from 'react';
import { BionicContext } from '../../../BionicContext';
import { Button } from '../../atoms/Button';
import { ModalInput } from '../../atoms/ModalInput';
import * as S from './styles';
import { InsertCodeScreenProps } from './types';

export const InsertCodeScreen = ({
  isModalOpen,
  setNextStep,
  identifier,
  setIdentifier,
}: InsertCodeScreenProps) => {
  const { postResetPassword } = useContext(BionicContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIdentifier('');
  }, [isModalOpen, setIdentifier]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await postResetPassword(identifier.replace(/\D/g, ''));
      setNextStep();
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <S.ModalContentWrapper>
      <S.TitleWrapper>
        <h3>É rapidinho!</h3>
        <span>
          Para sua segurança enviamos um código de confirmação ao seu e-mail
        </span>
      </S.TitleWrapper>
      <ModalInput
        id="identifier"
        value={identifier}
        onChange={setIdentifier}
        isRequired={true}
        mask="999.999.999-99"
        placeholder="000.000.000-00"
        label="Informe seu CPF"
      />
      <S.ButtonWrapper>
        <Button
          background="green"
          color="white"
          className="confirm-button"
          onClick={handleSubmit}
          disabled={!identifier.replace(/\D/g, '')}
          loading={isLoading}
        >
          Recuperar senha
        </Button>
      </S.ButtonWrapper>
    </S.ModalContentWrapper>
  );
};
