import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  line-height: 22.5px;
  font-weight: 400;
  max-width: 500px;
  gap: 24px;
`;

export const IndividualWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PersonWrapper = styled.div`
  display: flex;
  margin-right: 8px;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 14px;

    div {
      margin-left: 18px;
    }

    p:first-child {
      font-weight: 500;
    }
  }
`;

export const LinkedinWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 6px;

  span {
    line-height: 16px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
