import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BionicContext } from '../../../BionicContext';
import { Button } from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import { ModalInput } from '../../atoms/ModalInput';
import { ChangePasswordScreen } from '../../molecules/ChangePasswordScreen';
import { ModalComponent } from '../../molecules/Modal';
import * as S from './styles';
import { UserInfoModalProps } from './types';

export const UserInfoModal = ({
  isModalOpen,
  handleCloseModal,
}: UserInfoModalProps) => {
  const { logout, getUserInfo } = useContext(BionicContext);
  const [modalStep, setModalStep] = useState(1);
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const getUserInformation = useCallback(async () => {
    const userInfo = await getUserInfo();
    setName(userInfo.name);
    setLastName(userInfo.nickname);
    setEmail(userInfo.email);
  }, [getUserInfo]);

  const navigate = useNavigate();
  const handlePush = () => {
    navigate('/');
  };

  useEffect(() => {
    if (!isModalOpen) return;
    getUserInformation();
    setModalStep(1);
  }, [getUserInformation, isModalOpen]);

  const handleLogout = () => {
    logout();
    handleCloseModal();
    handlePush();
  };

  const isInfoScreen = () => modalStep === 1;

  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
    >
      {isInfoScreen() ? (
        <S.ModalContentWrapper>
          <S.TitleWrapper>
            <h3>Perfil</h3>
            <span>Minhas informações</span>
            <div>
              <S.AvatarIconWrapper>
                <Icon type="avatar" />
              </S.AvatarIconWrapper>
              <Button
                background="white"
                color="black"
                className="edit-button"
                onClick={() => setModalStep(2)}
              >
                Alterar Senha
              </Button>
            </div>
          </S.TitleWrapper>
          <ModalInput
            id="name"
            value={name}
            onChange={setName}
            placeholder="Nome"
            label="Nome"
            disabled
          />
          <ModalInput
            id="lastName"
            value={lastName}
            onChange={setLastName}
            placeholder="Sobrenome"
            label="Sobrenome"
            disabled
          />
          <ModalInput
            id="email"
            value={email}
            onChange={setEmail}
            placeholder="Nome@email.com"
            label="E-mail"
            disabled
          />
          <S.ButtonWrapper>
            <Button
              background="white"
              color="black"
              className="logout-button"
              onClick={handleLogout}
            >
              <Icon type="logout" />
              Logout
            </Button>
          </S.ButtonWrapper>
        </S.ModalContentWrapper>
      ) : (
        <ChangePasswordScreen
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
        />
      )}
    </ModalComponent>
  );
};
