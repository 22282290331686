import { useContext, useState } from 'react';
import { RatingHearts } from '../../atoms/RatingHearts';
import { BionicContext } from '../../../BionicContext';

import { Title } from '../../atoms/Title/styles';
import { RateCompanyModal } from '../../organisms/RateCompanyModal';
import * as S from './styles';
import { CompanyInfoProps } from './types';
import { RegisterIncentiveModal } from '../RegisterIncentiveModal';

export const CompanyInfo = ({
  company_id,
  company_name,
  rates_average,
  address,
}: CompanyInfoProps) => {
  const { isLogged, isCompany } = useContext(BionicContext);
  const [
    isRegisterIncentiveModalOpen,
    setIsRegisterIncentiveModalOpen,
  ] = useState(false);
  const [isRateCompanyModalOpen, setIsRateCompanyModalOpen] = useState(false);

  const handleRateCompany = () => {
    if (isLogged) {
      setIsRateCompanyModalOpen(true);
    } else {
      setIsRegisterIncentiveModalOpen(true);
    }
  };

  return (
    <>
      <S.CompanyWrapper>
        <Title fontSize={22} fontWeight={500}>
          Experiências compartilhadas sobre {company_name}
        </Title>
        <S.CompanyInfoWrapper>
          <S.CompanyDescriptionWrapper>
            <S.CompanyLogoDiv>
              {company_name
                .split(' ')
                .map((elem) => (elem = elem.charAt(0).toUpperCase()))
                .join('')}
            </S.CompanyLogoDiv>
            <div>
              <Title fontSize={22} fontWeight={500}>
                {company_name}
              </Title>
              {address}
            </div>
          </S.CompanyDescriptionWrapper>
          <S.RateCompanyWrapper>
            <S.RightContentWrapper>
              <RatingHearts
                value={rates_average}
                size="large"
                readOnly
                ratingHeartsLabel={`O quanto recomendam ${company_name}:`}
              />
              {!isCompany && (
                <S.RateCompanyButton
                  background="dark-green"
                  color="white"
                  className="rate-button"
                  onClick={handleRateCompany}
                >
                  Avaliar
                </S.RateCompanyButton>
              )}
            </S.RightContentWrapper>
          </S.RateCompanyWrapper>
        </S.CompanyInfoWrapper>
      </S.CompanyWrapper>
      <RateCompanyModal
        isModalOpen={isRateCompanyModalOpen}
        handleCloseModal={() => setIsRateCompanyModalOpen(false)}
        companyId={company_id}
      />
      {!isLogged && (
        <RegisterIncentiveModal
          isModalOpen={isRegisterIncentiveModalOpen}
          handleCloseModal={() => setIsRegisterIncentiveModalOpen(false)}
        />
      )}
    </>
  );
};

CompanyInfo.defaultProps = {};
