import { useContext, useEffect, useState } from 'react';

import * as S from './styles';

import { SignInModal } from '../../organisms/SignInModal';
import { SignUpModal } from '../../organisms/SignUpModal';
import { BionicContext } from '../../../BionicContext';
import { SignInModalProps } from '../../organisms/SignInModal/types';
import needLogin from '../../../assets/needLogin.svg';

export const RegisterIncentiveModal = ({
  isModalOpen,
  handleCloseModal,
}: SignInModalProps) => {
  const { isLogged } = useContext(BionicContext);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);

  useEffect(() => {
    if (isLogged) {
      handleCloseModal();
    }
  }, [handleCloseModal, isLogged]);

  return (
    <S.Modal
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      className="register-incentive-modal"
    >
      <S.Title>Para avaliar é necessário estar logado, vamos lá</S.Title>
      <img alt="Need Login to Rate" src={needLogin} />
      <S.RegisterButtons
        firstButtonLabel="Entrar"
        secondButtonLabel="Registrar"
        firstButtonOnClick={() => setIsSignInModalOpen(true)}
        secondButtonOnClick={() => setIsSignUpModalOpen(true)}
        className="register-buttons"
      />
      {!isLogged && (
        <>
          <SignInModal
            isModalOpen={isSignInModalOpen}
            handleCloseModal={() => setIsSignInModalOpen(false)}
          />
          <SignUpModal
            isModalOpen={isSignUpModalOpen}
            handleCloseModal={() => setIsSignUpModalOpen(false)}
          />
        </>
      )}
    </S.Modal>
  );
};
