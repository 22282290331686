import * as S from './styles';

import { RatingComment } from '../../molecules/RatingComment';
import { ListRatingCommentProps } from './types';

export const ListRatingComment = ({
  comments,
  is_company_admin,
}: ListRatingCommentProps) => {
  return (
    <S.ListRatingCommentWrapper>
      <S.ListRatingComment>
        {comments.map((comment, index) => (
          <RatingComment
            key={index}
            {...comment}
            is_company_admin={is_company_admin}
          />
        ))}
      </S.ListRatingComment>
    </S.ListRatingCommentWrapper>
  );
};
