import styled from 'styled-components';

export const ListRatingCommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px auto;

  @media (max-width: 768px) {
    padding: 0 20px;
`;

export const ListRatingComment = styled.div`
  & > div:not(:last-child) {
    margin-bottom: 18px;
  }
`;
