import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as S from './styles';

import Icon from '../../atoms/Icon';
import { RatingCommentProps } from './types';

import { BiChevronRight } from 'react-icons/bi';
import { AnswerCommentModal } from '../../organisms/AnswerCommentModal';

export const RatingComment = ({
  comment,
  date,
  title,
  is_comment_owner,
  comment_id,
  company_id,
  is_company_admin,
}: RatingCommentProps) => {
  const [isAnswerCommentModalOpen, setIsAnswerCommentModalOpen] = useState(
    false
  );
  const navigate = useNavigate();
  const handleCommentPush = () => {
    navigate(`/company/${company_id}/comment/${comment_id}`);
  };

  const handleOpenModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsAnswerCommentModalOpen(true);
  };

  return (
    <>
      <S.RatingComment onClick={handleCommentPush}>
        <S.CommentDescriptionWrapper>
          <span>{title}</span>
          <div>
            <Icon type="calendar" />
            <span>{date}</span>
          </div>
        </S.CommentDescriptionWrapper>

        <S.CommentWrapper>
          {comment}
          {is_company_admin && (
            <S.ResponseButton
              className="response-button"
              onClick={(event) => handleOpenModal(event)}
            >
              Responder
              <BiChevronRight size="24px" color="gray" />
            </S.ResponseButton>
          )}
        </S.CommentWrapper>
      </S.RatingComment>
      <AnswerCommentModal
        isModalOpen={isAnswerCommentModalOpen}
        handleCloseModal={() => setIsAnswerCommentModalOpen(false)}
        commentId={comment_id}
      />
    </>
  );
};
