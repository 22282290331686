import { CompanyInfoProps } from '../components/molecules/CompanyInfo/types';
import { CompanyRatingCardProps } from '../components/molecules/CompanyRatingCard/types';
import { RatingCardProps } from '../components/molecules/RatingCard/types';
import { CommentProps } from '../components/pages/Comment/types';
import { getAuthCookie } from '../utils';
import { api } from './api';
import {
  ContactSupportServiceProps,
  SignInServiceProps,
  RateCompanyServiceProps,
  AnswerCommentServiceProps,
  UpdateInfoServiceProps,
  RegisterCompanyServiceProps,
  SignUpServiceProps,
  SignInResponseProps,
  SignUpResponseProps,
  getTokenValidationResponseProps,
  GetUserInfoProps,
} from './services.types';

const userService = {
  // TODO separar os que nao sao services de user
  getRatingList: async (): Promise<RatingCardProps[]> => {
    const request = await api.get<RatingCardProps[]>(`/comment/ratings`);

    return request.data;
  },

  getRatingListWithUserLogged: async (
    userId: string
  ): Promise<RatingCardProps[]> => {
    const request = await api.get<RatingCardProps[]>(`/comment/by/user`, {
      params: { uc: userId },
      headers: {
        authorization: getAuthCookie(),
      },
    });

    return request.data;
  },

  getUserInfo: async (userId: string): Promise<GetUserInfoProps> => {
    const request = await api.get<GetUserInfoProps>(`/init/user`, {
      params: { uc: userId },
      headers: {
        authorization: getAuthCookie(),
      },
    });

    return request.data;
  },

  getCommentDetails: async (
    userId: string,
    commentId: string
  ): Promise<CommentProps> => {
    const request = await api.get<CommentProps>(`/comment/answer`, {
      params: { cc: commentId, uc: userId },
    });

    return request.data;
  },

  getCompanyDetails: async (companyId: string): Promise<CompanyInfoProps> => {
    const request = await api.get<CompanyInfoProps>(`/company/info`, {
      params: { cpj: companyId },
    });

    return request.data;
  },

  getCompanyDetailsWithUserLogged: async (
    userId: string,
    companyId: string
  ): Promise<CompanyInfoProps> => {
    const request = await api.get<CompanyInfoProps>(`/company/info`, {
      params: { uc: userId, cpj: companyId },
    });

    return request.data;
  },

  getSearchCompanies: async (
    searchBy: string
  ): Promise<CompanyRatingCardProps[]> => {
    const request = await api.get<CompanyRatingCardProps[]>(`/company/find`, {
      params: { search_value: searchBy },
    });

    return request.data;
  },

  getTokenValidation: async (
    token: string
  ): Promise<getTokenValidationResponseProps> => {
    const request = await api.get<getTokenValidationResponseProps>(
      `/validate-auth`,
      {
        headers: { authorization: token },
      }
    );

    return request.data;
  },

  postSignIn: async (
    signInData: SignInServiceProps
  ): Promise<SignInResponseProps> => {
    const request = await api.post(`/init/login`, signInData);

    return request.data;
  },

  postSignUp: async (
    signUpData: SignUpServiceProps
  ): Promise<SignUpResponseProps> => {
    const request = await api.post<SignUpResponseProps>(
      `/init/signup`,
      signUpData
    );

    return request.data;
  },

  postContactSupport: async (
    contactSupportData: ContactSupportServiceProps
  ): Promise<number> => {
    const request = await api.post(`/support`, contactSupportData);

    return request.status;
  },

  postResetPassword: async (identifier: string): Promise<void> => {
    await api.post(`/init/email/reset-pass`, { identifier });
  },

  postNewPassword: async (
    authCode: string,
    newPasswordValue: string
  ): Promise<void> => {
    await api.post(`/init/new-password`, {
      auth_code: authCode,
      new_password: newPasswordValue,
    });
  },

  postCheckVerificationCode: async (
    authCode: string
  ): Promise<SignUpResponseProps> => {
    const request = await api.post<SignUpResponseProps>(
      `/init/validate/admin`,
      {
        admin_access_code: authCode,
      }
    );

    return request.data;
  },

  postResendVerificationCode: async (identifier: string): Promise<void> => {
    await api.post<void>(`/init/send/admin-code`, {
      identifier,
    });
  },

  postRateCompany: async (
    rateCompanytData: RateCompanyServiceProps,
    userId: string
  ): Promise<number> => {
    const config = {
      headers: {
        authorization: getAuthCookie(),
        'x-cpj': rateCompanytData.companyId,
        'x-uc': userId,
      },
    };

    delete rateCompanytData.companyId;
    const request = await api.post(`/comment/new`, rateCompanytData, config);

    return request.status;
  },

  postAnswerComment: async (
    awnswerCommentData: AnswerCommentServiceProps,
    userId: string
  ): Promise<number> => {
    const config = {
      headers: {
        'x-uc': userId,
        'x-cc': awnswerCommentData.commentId,
        isCompany: true,
        authorization: getAuthCookie(),
      },
    };

    const request = await api.post(
      `/answers/new`,
      {
        body: awnswerCommentData.answer,
      },
      config
    );

    return request.status;
  },

  putUpdateInfo: async (
    updateInfoData: UpdateInfoServiceProps
  ): Promise<number> => {
    const config = {
      headers: {
        authorization: getAuthCookie(),
      },
    };

    const request = await api.post(
      `/init/update-pass`,
      {
        identifier: updateInfoData.identifier,
        new_password: updateInfoData.newPassword,
        old_password: updateInfoData.oldPassword,
      },
      config
    );

    return request.status;
  },

  registerCompany: async (
    registerCompanyData: RegisterCompanyServiceProps
  ): Promise<void> => {
    await api.post(`/register-company`, {
      data: registerCompanyData,
    });
  },
};

export default userService;
