import * as S from './styles';

import { Button } from '../../atoms/Button';
import { FormButtonSwitcherProps } from './types';

export const FormButtonSwitcher = ({
  selectedOption,
  firstButtonLabel,
  secondButtonLabel,
  firstButtonOnClick,
  secondButtonOnClick,
}: FormButtonSwitcherProps) => {
  const isFirstOptionSelected = () => {
    return selectedOption === 'first';
  };

  return (
    <S.FormButtonSwitcherWrapper>
      <Button
        className={`first-button ${isFirstOptionSelected() && 'selected'}`}
        onClick={firstButtonOnClick}
        color={isFirstOptionSelected() ? 'white' : 'green'}
        background={isFirstOptionSelected() ? 'green' : 'white'}
      >
        {firstButtonLabel}
      </Button>
      <Button
        className={`second-button ${!isFirstOptionSelected() && 'selected'}`}
        onClick={secondButtonOnClick}
        color={isFirstOptionSelected() ? 'green' : 'white'}
        background={isFirstOptionSelected() ? 'white' : 'green'}
      >
        {secondButtonLabel}
      </Button>
    </S.FormButtonSwitcherWrapper>
  );
};
