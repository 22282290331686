import styled from 'styled-components';

export const MainWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 30px 62px;
  max-width: 1440px;
  width: 100%;
  margin: auto;

  @media (max-width: 768px) {
    padding: 30px 20px;
  }
`;

export const MainContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin-top: 48px;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const SpinWrapper = styled.div`
  display: flex;
  flex: 1;
  margin-top: 60px;
  min-height: 160px;
  align-items: center;
  justify-content: center;
`;
