import { useContext, useEffect, useState } from 'react';
import { BionicContext } from '../../../BionicContext';
import { Button } from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import { ModalInput } from '../../atoms/ModalInput';
import * as S from './styles';
import { ChangePasswordScreenProps } from './types';

export const ChangePasswordScreen = ({
  isModalOpen,
  handleCloseModal,
}: ChangePasswordScreenProps) => {
  const { userId, putUpdateInfo } = useContext(BionicContext);
  const [isLoading, setIsLoading] = useState(false);
  const [oldPasswordValue, setOldPasswordValue] = useState('');
  const [newPasswordValue, setNewPasswordValue] = useState('');

  useEffect(() => {
    setOldPasswordValue('');
    setNewPasswordValue('');
  }, [isModalOpen]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await putUpdateInfo({
        identifier: userId,
        oldPassword: oldPasswordValue,
        newPassword: newPasswordValue,
      });
      handleCloseModal();
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <S.ModalContentWrapper>
      <S.TitleWrapper>
        <h3>Alterar senha</h3>
        <span>Suas informações</span>
        <S.AvatarIconWrapper>
          <Icon type="avatar" />
        </S.AvatarIconWrapper>
      </S.TitleWrapper>
      <ModalInput
        type="password"
        id="senha-atual"
        value={oldPasswordValue}
        onChange={setOldPasswordValue}
        placeholder="Digite a senha atual"
        label="Senha atual"
      />
      <ModalInput
        type="password"
        id="senha-nova"
        value={newPasswordValue}
        onChange={setNewPasswordValue}
        placeholder="Digite a nova senha"
        label="Nova senha"
      />
      <S.ButtonWrapper>
        <Button
          background="green"
          color="white"
          className="confirm-button"
          onClick={handleSubmit}
          loading={isLoading}
          disabled={!oldPasswordValue || !newPasswordValue}
        >
          Confirmar
        </Button>
      </S.ButtonWrapper>
    </S.ModalContentWrapper>
  );
};
