import { useState } from 'react';

import * as S from './styles';

import { ListRatingCardProps } from './types';
import { RatingCard } from '../../molecules/RatingCard';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';

export const ListRatingCard = ({ ratingList }: ListRatingCardProps) => {
  const [scrollX, setScrollX] = useState(0);

  const onClickLeftArrow = () => {
    const scrollWidth = window.innerWidth > 1440 ? 1440 : window.innerWidth;
    let x = scrollX + Math.round(scrollWidth / 2);
    if (x > 0) {
      x = 0;
    }
    setScrollX(x);
  };
  const onClickRightArrow = () => {
    const scrollWidth = window.innerWidth > 1440 ? 1440 : window.innerWidth;
    let x = scrollX - Math.round(scrollWidth / 2);
    let listW = ratingList.length * 328;

    if (scrollWidth - listW > x) {
      x = scrollWidth - listW - 80;
    }

    setScrollX(x);
  };

  return (
    <S.Container>
      {ratingList.length * 328 > 1440 && (
        <S.ChevronWrapper>
          <HiOutlineChevronLeft
            className="carousel-left"
            size="72px"
            color="#FFF"
            onClick={onClickLeftArrow}
          />
          <HiOutlineChevronRight
            className="carousel-right"
            size="72px"
            color="#FFF"
            onClick={onClickRightArrow}
          />
        </S.ChevronWrapper>
      )}
      <S.ListRatingCardWrapper>
        <S.ListRatingCard marginLeft={scrollX} width={ratingList.length * 328}>
          {ratingList.map(({ comment, company, title, comment_id }, index) => (
            <RatingCard
              key={index}
              comment={comment}
              company={company}
              title={title}
              comment_id={comment_id}
            />
          ))}
        </S.ListRatingCard>
      </S.ListRatingCardWrapper>
    </S.Container>
  );
};
