import { useContext, useEffect, useState } from 'react';
import { BionicContext } from '../../../BionicContext';
import { Button } from '../../atoms/Button';
import { ModalInput } from '../../atoms/ModalInput';
import { ModalComponent } from '../../molecules/Modal';
import * as S from './styles';
import { RegisterCompanyModalProps } from './types';

export const RegisterCompanyModal = ({
  isModalOpen,
  handleCloseModal,
}: RegisterCompanyModalProps) => {
  const { registerCompany } = useContext(BionicContext);
  const [isLoading, setIsLoading] = useState(false);
  const [identifier, setIdentifier] = useState('');
  const [companyName, setCompanyName] = useState('');

  useEffect(() => {
    setIdentifier('');
    setCompanyName('');
  }, [isModalOpen]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await registerCompany({
        name: companyName,
        cnpj: identifier,
      });
      handleCloseModal();
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
    >
      <S.ModalContentWrapper>
        <S.TitleWrapper>
          <h3>Cadastrar uma empresa</h3>
        </S.TitleWrapper>
        <ModalInput
          id="identifier"
          value={identifier}
          onChange={setIdentifier}
          mask="99.999.999/9999-99"
          placeholder="00.000.000/0000-00"
          label="CNPJ"
        />
        <ModalInput
          id="company-name"
          value={companyName}
          onChange={setCompanyName}
          placeholder="Digite o nome da empresa"
          label="Nome"
        />
        <Button
          background="green"
          color="white"
          className="send-button"
          loading={isLoading}
          onClick={() => handleSubmit()}
          disabled={!(identifier && companyName)}
        >
          Enviar
        </Button>
      </S.ModalContentWrapper>
    </ModalComponent>
  );
};
