import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import * as S from './styles';

import { Header } from '../../templates/Header';

import { Footer } from '../../molecules/Footer';
import { CommentCard } from '../../molecules/CommentCard';
import { Breadcrumb } from '../../molecules/Breadcrumb';
import { BreadcrumbListProps } from '../../molecules/Breadcrumb/types';
import { BionicContext } from '../../../BionicContext';
import { CommentProps } from './types';
import SnackBar from '../../atoms/SnackBar';
import Spin from '../../atoms/Spin';

export const Comment = () => {
  const {
    getCommentDetails,
    handleCloseSnackBar,
    snackBarAlertProps,
  } = useContext(BionicContext);
  const { comment_id, company_id } = useParams();
  const [breadcrumbList, setBreadcrumbList] = useState<BreadcrumbListProps[]>(
    []
  );
  const [commentDetails, setCommentDetails] = useState<CommentProps>(
    {} as CommentProps
  );

  useEffect(() => {
    const dispatchGetCommentDetails = async () => {
      const commentDetails = await getCommentDetails(comment_id || '');
      setCommentDetails(commentDetails);
    };
    dispatchGetCommentDetails();
    setBreadcrumbList([
      {
        ariaLabel: 'Voltar para empresa',
        label: 'Empresa',
        href: `/company/${company_id}/ratings`,
      },
      {
        ariaLabel: 'Página atual de comentário',
        label: 'Comentário',
      },
    ]);
  }, [comment_id, company_id, getCommentDetails]);

  return (
    <>
      <Header />
      <S.MainWrapper>
        <Breadcrumb breadcrumbList={breadcrumbList} />
        {commentDetails.comment && commentDetails.comment.comment_id ? (
          <>
            <h2>{commentDetails.comment.title}</h2>
            <S.MainContentWrapper>
              <CommentCard
                allowedResponse={commentDetails.is_company_admin}
                title={`Comentário registrado em ${
                  commentDetails.comment.date
                }.`}
                comment_id={comment_id || ''}
              >
                {commentDetails.comment.comment}
              </CommentCard>
              {commentDetails.answer.comment_id && (
                <CommentCard
                  allowedResponse={false}
                  title={`Respondido em ${commentDetails.answer.date}.`}
                  comment_id={commentDetails.answer.comment_id || ''}
                >
                  {commentDetails.answer.body}
                </CommentCard>
              )}
            </S.MainContentWrapper>
          </>
        ) : (
          <S.SpinWrapper>
            <Spin size={60} />
          </S.SpinWrapper>
        )}
      </S.MainWrapper>
      {snackBarAlertProps.show && (
        <SnackBar
          type={snackBarAlertProps.type}
          onClose={handleCloseSnackBar}
          open={snackBarAlertProps.show}
        >
          {snackBarAlertProps.message}
        </SnackBar>
      )}
      <Footer />
    </>
  );
};

export default Comment;
