import styled from 'styled-components';

export const ContentWrapper = styled.div`
  max-width: 768px;
  margin: 0 auto;
  width: 100%;
`;

export const SpinWrapper = styled.div`
  display: flex;
  flex: 1;
  margin-top: 60px;
  min-height: 160px;
  align-items: center;
  justify-content: center;
`;
