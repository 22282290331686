import styled from 'styled-components';

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  button {
    align-self: flex-end;
  }

  input {
    margin-bottom: 21px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  h3 {
    font-size: 20px;
    font-weight: 700;
    color: var(--black);
    margin-bottom: 12px;
  }

  @media (max-width: 768px) {
    align-items: center;
  }
`;

export const ForgotPasswordSpan = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: #8ea2e7;
  font-family: IBM Plex Sans;
  cursor: pointer;
  margin-bottom: 20px;
`;
