import styled from 'styled-components';

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 40px 0;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const CloseButton = styled.div`
  display: none;
  cursor: pointer;
  position: absolute;
  right: 20px;

  @media (min-width: 768px) {
    display: flex;
  }
`;
