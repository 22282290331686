import React, { memo, Suspense } from 'react';
import { IconProps } from './types';

const Icons = {
  avatar: React.lazy(() => import('./assets/avatar')),
  bionic: React.lazy(() => import('./assets/bionic')),
  like: React.lazy(() => import('./assets/like')),
  dislike: React.lazy(() => import('./assets/dislike')),
  search: React.lazy(() => import('./assets/search')),
  logged: React.lazy(() => import('./assets/logged')),
  calendar: React.lazy(() => import('./assets/calendar')),
  linkedin: React.lazy(() => import('./assets/linkedin')),
  logout: React.lazy(() => import('./assets/logout')),
};

/**
 * Icon
 */
const Icon = ({ type, className, ...props }: IconProps) => {
  const IconComponent = Icons[type];
  return (
    <Suspense>
      <IconComponent {...props} data-testid="icon-component" />
    </Suspense>
  );
};

export default memo(Icon);
