import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SupportModal } from '../../organisms/SupportModal';
import * as S from './styles';
import { MenuItemsProps } from './types';

export const MenuItems = ({ currentPage }: MenuItemsProps) => {
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);

  const navigate = useNavigate();
  const handleAboutUsPush = () => {
    navigate('/about');
  };

  return (
    <S.MenuItems>
      {currentPage !== 'about' && (
        <S.MenuListItem onClick={() => handleAboutUsPush()}>
          Sobre nós
        </S.MenuListItem>
      )}
      <S.MenuListItem onClick={() => setIsSupportModalOpen(true)}>
        Suporte
      </S.MenuListItem>
      <SupportModal
        isModalOpen={isSupportModalOpen}
        handleCloseModal={() => setIsSupportModalOpen(false)}
      />
    </S.MenuItems>
  );
};
