import styled from 'styled-components';
import InputMask from 'react-input-mask';

export const ModalInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ModalInputWrapper = styled.div`
  display: flex;
  position: relative;

  svg {
    position: absolute;
    right: 13px;
    top: 13px;
    cursor: pointer;
  }
`;

export const ModalInput = styled(InputMask)`
  width: 100%;
  border: 0;
  border-radius: 4px;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px;
  background: #f8fafc;

  :focus,
  input:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--text-secondary-gray-3);
  }
`;

interface LabelProps {
  isRequired: boolean;
}

export const ModalInputLabel = styled.label<LabelProps>`
  margin-bottom: 6px;
  font-family: IBM Plex Sans;

  ${(props) =>
    props.isRequired &&
    `::after {
      content: ' *';
      color: #ED0131;
    }`}
`;
