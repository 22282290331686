import { useNavigate } from 'react-router-dom';

import * as S from './styles';

import Icon from '../../atoms/Icon';
import { CompanyRatingCardProps } from './types';

export const CompanyRatingCard = ({
  address,
  name,
  dislike,
  likes,
  cnpj,
  category,
}: CompanyRatingCardProps) => {
  const navigate = useNavigate();
  const handleRatingPush = () => {
    navigate(`/company/${cnpj}/ratings`);
  };

  return (
    <S.CompanyRatingCard onClick={() => handleRatingPush()}>
      <S.CompanyWrapper>
        <S.AvatarIconWrapper>
          <Icon type="avatar" />
        </S.AvatarIconWrapper>
        <h5>{name}</h5>
      </S.CompanyWrapper>
      <S.InformationWrapper>
        <span>{address}</span>
        <S.RatingCountWrapper>
          <div>
            <Icon type="like" />
            <span>{likes}</span>
          </div>
          <div>
            <Icon type="dislike" />
            <span>{dislike}</span>
          </div>
        </S.RatingCountWrapper>
      </S.InformationWrapper>
    </S.CompanyRatingCard>
  );
};
