import * as S from './styles';
import { RegisterCompanyProps } from './types';

export const RegisterCompany = ({ onClick }: RegisterCompanyProps) => {
  return (
    <S.ContentWrapper>
      <h3>Primeira vez que procuram essa empresa</h3>
      <S.RegisterButton
        background="green"
        color="white"
        className="response-button"
        onClick={onClick}
      >
        Bora cadastrar?
      </S.RegisterButton>
    </S.ContentWrapper>
  );
};
