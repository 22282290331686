import * as S from './styles';
import signin from '../../../assets/signin.svg';

export const ProceedLoginScreen = () => {
  return (
    <S.ModalContentWrapper>
      <S.TitleWrapper>
        <h3>Cadastro realizado com sucesso!</h3>
        <span>O acesso empresa será liberado em até 48 horas.</span>
        <img alt="login-criado" src={signin} />
        <p>A gente avisa por e-mail.</p>
      </S.TitleWrapper>
    </S.ModalContentWrapper>
  );
};
