import styled from 'styled-components';

export const CompanyRatingCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px;
  gap: 8px;
  border-radius: 10px;
  background: var(--light-green-1);
  overflow: hidden;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    transform: scale(1);
  }

  h3 {
    font-size: 20px;
  }

  @media (min-width: 768px) {
    padding: 10px;
    transform: scale(0.9);
    transition: all ease 0.2s;
    min-width: 328px;

    &:hover {
      transform: scale(1);
    }
  }
`;

export const AvatarIconWrapper = styled.div`
  display: flex;
  width: 47px;
  height: 44px;
  border-radius: 4px;
  background: #bdbdbd;
  justify-content: center;
  align-items: center;
`;

export const InformationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  border-top: 1px solid #6d8d6f;
  padding: 10px 4px 0 4px;

  & > span {
    align-self: flex-start;
  }
`;

export const CompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h5 {
    margin-top: 16px;
  }
`;

export const RatingCountWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    align-items: flex-start;

    span {
      margin-left: 5px;
    }
  }
`;
