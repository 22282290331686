import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  max-width: 500px;
  align-items: center;
  text-align: center;
  gap: 30px;

  @media (max-width: 768px) {
    p {
      text-align: start;
      font-size: 12px;
      font-weight: 600;
    }
  }
`;
